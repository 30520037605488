import { api } from 'app/utils/api';
import { SIMULATION_ENDPOINTS } from 'app/utils/endpoints';
import { WithCurrencyInfo, WithEmptyOption } from 'app/utils/types';

export type RiskProfileVariants = 'very_conservative' | 'conservative' | 'moderate' | 'aggressive' | 'very_aggressive';
export type SimulateVariants = 'time_frame' | 'monthly_contribution';

export interface BaseMutualsFundsSimulation extends WithEmptyOption {
  simulate: SimulateVariants,
  risk_profile: RiskProfileVariants
}

export interface BaseMutualFundsResponse extends WithCurrencyInfo {
  profit: number
}
export interface TimeFrameVariantSimulation extends BaseMutualsFundsSimulation {
  monthly_contribution: number
  goal: number
}
export interface MonthlyContributionVariantSimulation extends BaseMutualsFundsSimulation {
  time_frame: number
  goal: number,
}

export interface TimeFrameVariantResponse extends BaseMutualFundsResponse {
  time_frame: number
}

export interface MonthlyContributionVariantResponse extends BaseMutualFundsResponse {
  monthly_contribution: number
}

export type MutualFundsSimulationVariants =
  TimeFrameVariantSimulation |
  MonthlyContributionVariantSimulation;

export type MutualFundsSimulationVariantsResponse =
  TimeFrameVariantResponse |
  MonthlyContributionVariantResponse;

export const mutualFundsService = {
  simulate: async (payload: MutualFundsSimulationVariants) => api
    .post<MutualFundsSimulationVariantsResponse>(
    SIMULATION_ENDPOINTS.MUTUAL_FUNDS,
    payload
  ),
};
