import {
  SimulateVariants,
  MonthlyContributionVariantResponse,
  MonthlyContributionVariantSimulation,
  MutualFundsSimulationVariants,
  MutualFundsSimulationVariantsResponse,
  TimeFrameVariantResponse,
  TimeFrameVariantSimulation,
} from './mutualFundsService';

export type ChartDataGeneratorFn = (
  parameters: MutualFundsSimulationVariants,
  response: MutualFundsSimulationVariantsResponse) => {
    totalSavings: number,
    profit: number
  };

const generateTimeFrameChartData: ChartDataGeneratorFn = (parameters, response) => {
  const { monthly_contribution } = parameters as TimeFrameVariantSimulation;
  const { time_frame, profit } = response as TimeFrameVariantResponse;

  const totalSavings = monthly_contribution * time_frame;

  return { totalSavings, profit };
};

const generateMonthlyContributionChartData: ChartDataGeneratorFn = (parameters, response) => {
  const { time_frame } = parameters as MonthlyContributionVariantSimulation;
  const { monthly_contribution, profit } = response as MonthlyContributionVariantResponse;

  const totalSavings = monthly_contribution * time_frame * 12;

  return { totalSavings, profit };
};

export const chartDataGenerator: Record<SimulateVariants, ChartDataGeneratorFn> = {
  monthly_contribution: generateMonthlyContributionChartData,
  time_frame: generateTimeFrameChartData,
};
