import {
  Genders, RegimeLiteral, SavingsOption, WithContribution, WithCurrencyInfo, WithEmptyOption,
} from 'app/utils/types';
import { api } from 'app/utils/api';
import { SIMULATION_ENDPOINTS } from 'app/utils/endpoints';
import { SummarizedRiskProfiles } from 'app/utils/constants';

export interface ProvisionalSavingsPayload extends WithEmptyOption, WithContribution {
  ideal_pension?: number
  monthly_savings?: number
  date_of_birth: string
  gender: Genders
  net_salary: number
  afp_balance: number
  risk_profile: SummarizedRiskProfiles
  apv_regime: Uppercase<RegimeLiteral>
  savings_option: SavingsOption
  expected_retirement?: number
}

export interface SavingsByYear {
  expected_fiscal_contribution: number
  expected_personal_contribution: number
  year: number
}

export interface ProvisionalSavingsResponse extends WithCurrencyInfo {
  afp_annuity: number
  afp_with_transfer_apv_annuity: number
  expected_annuity: number
  fiscal_contribution_without_interest: number
  ideal_pension: number
  monthly_savings: number
  monthly_personal_payment: number
  monthly_tax_deduction: number
  pension_rise: number
  personal_savings_without_interest: number
  savings_by_year: SavingsByYear[]
  savings_expected_return: number
  total_fiscal_contribution: number
  total_personal_contribution: number
  total_yearly_savings: number
  total_savings: number
  total_afp_savings: number
  yearly_benefit: number
  yearly_personal_contribution: number
}

export const provisionalSavingsService = {
  simulate: async (payload: ProvisionalSavingsPayload) => api
    .post<ProvisionalSavingsResponse>(SIMULATION_ENDPOINTS.PROVISIONAL_SAVINGS, payload),

};
