import { getIcon } from 'app/utils/staticStorage';
import React from 'react';
import { BaseAlert } from './BaseAlert';

export function InformativeDeclarationDisclaimer() {
  return (
    <BaseAlert
      alertClassName="text-body small border-1 border-info"
      icon={{
        src: getIcon('info.svg'),
        alt: 'info icon',
      }}
      type="alert-info"
      renderContent={() => (
        <p className="m-0">
          La presente simulación es una estimación meramente informativa,
          basada en datos generales y no constituye una oferta comercial.
        </p>
      )}
    />

  );
}
