import React from 'react';

import { HorizontalStackBarChart, VerticalStackBarChart } from 'app/components/charts';
import {
  BaseAlert,
  Block, BlockTitle, FieldDisplayer, LoadingSpinner, OuterHeader,
} from 'app/components/common';
import { SavingsCards } from 'app/components/savingsCards';
import { numberFormatter } from 'app/utils/formatters';
import { RegimeLiteral, StackedBarChartConfig, WithCurrencySwitch } from 'app/utils/types';
import { getIcon } from 'app/utils/staticStorage';

import { getInsuranceWithSavingsWording } from 'app/constants/wording/insuranceWithSavings';
import { getQueryParams } from 'app/utils/queryParams';
import { MEDIA_QUERIES, useMediaQuery } from 'app/utils/hooks/useMediaQuery';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { CurrencySwitch } from 'app/components/common/CurrencySwitch';
import { useInsuranceWithSavings } from './useInsuranceWithSavings';
import { InsuranceWithSavingsPayload, InsuranceWithSavingsRegime } from './insuranceWithSavingsService';

interface InsuranceWithSavingsContentProps extends WithCurrencySwitch {
  chartData: StackedBarChartConfig
  simulationResult: InsuranceWithSavingsRegime
}

function InsuranceWithSavingsContent({
  chartData,
  simulationResult,
  showCurrencySwitch,
}: InsuranceWithSavingsContentProps) {
  const {
    current_year_benefit,
    current_year_savings,
    monthly_pension_improvement,
    total_apv_savings,
    regime,
  } = simulationResult;

  const isMobile = useMediaQuery(MEDIA_QUERIES.MOBILE);
  const { wording } = getInsuranceWithSavingsWording();
  const { gender } = getQueryParams<InsuranceWithSavingsPayload>();
  const { currencyPrefix } = useCurrencyContext();

  const retirementYears = gender === 'MALE' ? 65 : 60;

  const totalSavings = current_year_benefit + current_year_savings;

  return (
    <section className="h-100 d-flex flex-column" style={{ maxWidth: 700 }}>

      <OuterHeader renderContent={(
        <>
          <span>{`Según ${wording.your} perfil ${wording.suggestion} recomendamos `}</span>
          <span className="text-secondary">
            {`APV - Régimen ${regime}: `}
          </span>
        </>
      )}
      />

      <div className="simulation-renderer simulation-renderer-shadow">
        {showCurrencySwitch && <CurrencySwitch />}

        <Block className="mb-3_5">
          <BlockTitle
            extraClassName="mb-3_5 fs-base"
            primaryText={{ content: `Si ${wording.start} a ahorrar`, extraClassName: 'fw-normal' }}
            secondaryText={{ content: `hoy, en el año ${wording.willHave} ahorrado:`, color: 'text-dark' }}
          />

          <SavingsCards
            currentYearBenefit={current_year_benefit}
            currentYearSavings={current_year_savings}
            totalSavings={totalSavings}
            regime={regime.toLowerCase() as RegimeLiteral}
          />
        </Block>

        <Block className="mb-4">
          <BlockTitle
            extraClassName="mb-4 fs-6 "
            primaryText={{ content: `Así proyectamos ${wording.yours} ahorros hasta cuando ${wording.wouldBe} ${wording.retirement} a` }}
            secondaryText={{ content: `los ${retirementYears} años:` }}
          />

          <div className="d-md-flex justify-content-between gap-3 mb-4">
            <div>
              <header className="small fw-bold mb-2 text-dark">
                <span className="text-capitalize">{`${wording.your} `}</span>
                <span>{'pensión podría '}</span>
                <span className="text-secondary">mejorar en:</span>
              </header>
              <FieldDisplayer
                extraClassName="fs-1 w-100 bg-light-secondary"
                primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                secondaryText={{ content: r(monthly_pension_improvement), color: 'text-dark' }}
              />
            </div>

            <div>
              <header className="small fw-bold mb-2 text-dark">
                <span className="text-capitalize">{`${wording.willSave} `}</span>
                <span>{`ahorrado en ${wording.your} `}</span>
                <span className="text-secondary">Seguro con APV:</span>
              </header>

              <FieldDisplayer
                extraClassName="fs-1 w-100 bg-light-secondary"
                primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                secondaryText={{ content: r(total_apv_savings), color: 'text-dark' }}
              />
            </div>
          </div>

        </Block>

        <Block>

          {isMobile
            ? <VerticalStackBarChart chartData={chartData} />
            : <HorizontalStackBarChart chartData={chartData} labelCol={6} />}
        </Block>
      </div>
    </section>
  );
}

function EmptyContent() {
  const { wording } = getInsuranceWithSavingsWording();
  const isMobile = useMediaQuery(MEDIA_QUERIES.MOBILE);

  return (
    <section className="h-100 d-flex flex-column" style={{ width: isMobile ? '100%' : 700 }}>

      <OuterHeader renderContent={(<span>{`Completa ${wording.yours} datos:`}</span>)} />

      <div className="simulation-renderer simulation-renderer-shadow mb-2 w-100">
        <BlockTitle
          extraClassName="mb-4 fs-6"
          primaryText={{ content: `Así proyectamos ${wording.yours} ahorros para cuando ${wording.wouldBe} ${wording.retirement}: ` }}
        />

        <Block className="d-md-flex gap-3 mb-4 justify-content-between">
          <div className="w-100">
            <header className="small fw-bold mb-2 text-dark">
              <span className="text-capitalize">{`${wording.your} `}</span>
              <span>{'pensión podría '}</span>
              <span className="text-secondary">mejorar en:</span>
            </header>
            <FieldDisplayer
              extraClassName="fs-1 w-100 bg-light-secondary"
              primaryText={{ content: '$', color: 'text-secondary' }}
              secondaryText={{ content: numberFormatter.format(0), color: 'text-dark' }}
            />
          </div>

          <div className="w-100">
            <header className="small fw-bold mb-2 text-dark">
              <span className="text-capitalize">{`${wording.willSave} `}</span>
              <span>{`ahorrado en ${wording.your} `}</span>
              <span className="text-secondary">Seguro con APV:</span>
            </header>

            <FieldDisplayer
              extraClassName="fs-1 w-100 bg-light-secondary"
              primaryText={{ content: '$', color: 'text-secondary' }}
              secondaryText={{ content: numberFormatter.format(0), color: 'text-dark' }}
            />
          </div>
        </Block>

        <div className="d-flex align-items-center justify-content-center p-5 my-5">
          <figure className="d-flex flex-column  align-items-center justify-content-center">
            <img src={getIcon('rocket.svg')} alt="Emtpy simulation state" className="mb-2" />

            <figcaption className="text-primary text-wrap text-center h6 fw-bold lh-base" style={{ width: 200 }}>
              {`Aquí verás como mejora ${wording.your} pensión`}
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
}

type InsuranceWithSavingsProps = WithCurrencySwitch;

export function InsuranceWithSavings({ showCurrencySwitch }: InsuranceWithSavingsProps) {
  const {
    simulationResult,
    isError,
    isLoading,
    chartData,
    isEmpty,
  } = useInsuranceWithSavings();

  if (isEmpty) {
    return <EmptyContent />;
  }

  if (simulationResult && chartData) {
    return (
      <InsuranceWithSavingsContent
        showCurrencySwitch={showCurrencySwitch}
        simulationResult={simulationResult}
        chartData={chartData}
      />
    );
  }

  if (isLoading) return <LoadingSpinner color="primary" size={256} borderWidth={4} />;
  if (isError) return <BaseAlert type="alert-danger" text="Oh no! Algo ha ido mal. Intente más tarde..." />;

  return null;
}
