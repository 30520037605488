import React from 'react';

import { HorizontalStackBarChart, VerticalStackBarChart } from 'app/components/charts';
import { SavingsCards } from 'app/components/savingsCards';
import {
  BaseAlert,
  Block,
  BlockTitle,
  FieldDisplayer,
  LoadingSpinner,
  OuterHeader,
} from 'app/components/common';
import { numberFormatter } from 'app/utils/formatters';
import { StackedBarChartConfig } from 'app/utils/types';

import { MEDIA_QUERIES, useMediaQuery } from 'app/utils/hooks/useMediaQuery';
import { getIcon } from 'app/utils/staticStorage';
import { getQueryParams } from 'app/utils/queryParams';
import { getAPVWording } from 'app/constants/wording/apv';
import { useApv } from './useApv';
import { ApvProjectedSavingsPayload, ApvSimulationData } from './apvService';

interface ApvContentProps {
  data: ApvSimulationData
  chartData: StackedBarChartConfig
}

function ApvContent({ data, chartData }: ApvContentProps) {
  const {
    total_savings,
    regime,
    pension_rise,
    current_year_savings,
  } = data;

  const { gender } = getQueryParams<ApvProjectedSavingsPayload>();
  const retirementYears = gender === 'MALE' ? 65 : 60;

  const { current_year_benefit } = data[regime.regimeProperty];
  const isMobile = useMediaQuery(MEDIA_QUERIES.MOBILE);
  const { wording } = getAPVWording();

  return (
    <section className="h-100 d-flex flex-column" style={{ maxWidth: 710 }}>
      <OuterHeader renderContent={(
        <>
          <span>{`Según ${wording.your} perfil ${wording.suggestion} recomendamos `}</span>
          <span className="text-secondary">
            {`APV - Régimen ${regime.regimeLiteral.toUpperCase()}:`}
          </span>
        </>
      )}
      />

      <div className="simulation-renderer">
        <Block>
          <BlockTitle
            extraClassName="mb-3_5 fs-base"
            primaryText={{ content: `Si ${wording.start} a ahorrar`, extraClassName: 'fw-normal' }}
            secondaryText={{ content: `hoy, en el año ${wording.willHave} ahorrado:`, color: 'text-dark' }}
          />

          <div className="d-flex justify-content-center w-100">
            <SavingsCards
              currentYearBenefit={current_year_benefit}
              currentYearSavings={current_year_savings}
              totalSavings={current_year_benefit + current_year_savings}
              regime={regime.regimeLiteral}
            />
          </div>
        </Block>

        <Block className="d-md-flex gap-3 mb-4">
          <div className="w-100">
            <header className="small fw-bold mb-2 text-dark">
              <span className="text-capitalize">{`${wording.your} `}</span>
              <span className="text-secondary">{'total de ahorros '}</span>
              <span>podría ser de:</span>
            </header>
            <FieldDisplayer
              extraClassName="fs-1 w-100 bg-light-secondary"
              primaryText={{ content: '$', color: 'text-secondary' }}
              secondaryText={{ content: numberFormatter.format(total_savings), color: 'text-dark' }}
            />
          </div>

          <div className="w-100">
            <header className="small fw-bold mb-2 text-dark">
              <span className="text-capitalize">{`${wording.your} `}</span>
              <span className="text-secondary">{'pensión podría mejorar '}</span>
              <span>en:</span>
            </header>

            <FieldDisplayer
              extraClassName="fs-1 w-100 bg-light-secondary"
              primaryText={{ content: '$', color: 'text-secondary' }}
              secondaryText={{ content: numberFormatter.format(pension_rise), color: 'text-dark' }}
            />
          </div>
        </Block>

        <Block>
          <header className="mb-3 text-center text-dark">
            <h4 className="fs-6 fw-bold">
              <span>{`Así proyectamos ${wording.yours} ahorros hasta cuando ${wording.wouldBe} ${wording.retirement} a `}</span>
              <span className="text-secondary">{`los ${retirementYears} años `}</span>
              <span>en &nbsp;</span>
              <span className="text-secondary">
                {`APV - Régimen ${regime.regimeLiteral.toUpperCase()}:`}
              </span>
            </h4>
          </header>

          {isMobile
            ? <VerticalStackBarChart chartData={chartData} />
            : <HorizontalStackBarChart chartData={chartData} />}
        </Block>
      </div>
    </section>
  );
}

function EmptyContent() {
  const { wording } = getAPVWording();
  const isMobile = useMediaQuery(MEDIA_QUERIES.MOBILE);

  return (
    <section className="h-100 d-flex flex-column" style={{ width: isMobile ? '100%' : 710 }}>

      <OuterHeader renderContent={(<span>{`Completa ${wording.yours} datos:`}</span>)} />

      <div className="simulation-renderer flex-grow-1 mb-2">
        <Block className="d-md-flex gap-3 mb-4">
          <div className="w-100">
            <header className="small fw-bold mb-2 text-dark">
              <span className="text-capitalize">{`${wording.your} `}</span>
              <span className="text-secondary">{'total de ahorros '}</span>
              <span>podría ser de:</span>
            </header>
            <FieldDisplayer
              extraClassName="fs-1 w-100 bg-light-secondary"
              primaryText={{ content: '$', color: 'text-secondary' }}
              secondaryText={{ content: numberFormatter.format(0), color: 'text-dark' }}
            />
          </div>

          <div className="w-100">
            <header className="small fw-bold mb-2 text-dark">
              <span className="text-capitalize">{`${wording.your} `}</span>
              <span className="text-secondary">{'pensión podría mejorar '}</span>
              <span>en:</span>
            </header>

            <FieldDisplayer
              extraClassName="fs-1 w-100 bg-light-secondary"
              primaryText={{ content: '$', color: 'text-secondary' }}
              secondaryText={{ content: numberFormatter.format(0), color: 'text-dark' }}
            />
          </div>
        </Block>

        <div className="d-flex align-items-center justify-content-center p-5 my-5">
          <figure className="d-flex flex-column  align-items-center justify-content-center">
            <img src={getIcon('rocket.svg')} alt="Emtpy simulation state" className="mb-2" />

            <figcaption className="text-primary text-wrap text-center h6 fw-bold lh-base" style={{ width: 200 }}>
              {`Aquí verás como mejora ${wording.your} pensión`}
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
}

export function Apv() {
  const {
    apvSimulationData, chartData, isError, isLoading, isEmpty,
  } = useApv();

  if (isEmpty) {
    return <EmptyContent />;
  }

  if (apvSimulationData && chartData) {
    return (
      <ApvContent chartData={chartData} data={apvSimulationData} />
    );
  }

  if (isLoading) return <LoadingSpinner color="primary" size={256} borderWidth={4} />;
  if (isError) return <BaseAlert type="alert-danger" text="Oh no! Algo ha ido mal. Intente más tarde..." />;

  return null;
}
