/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export interface BenefitCardProps {
  icon: {
    src: string
    alt: string
  }
  renderContent: () => JSX.Element
  id: string
}

function BenefitCard({ icon, renderContent }: BenefitCardProps) {
  return (
    <article className="bg-white h-100 shadow py-2 px-3 rounded-5 d-flex flex-column justify-content-center align-items-center">
      <figure className="mb-2">
        <img src={icon.src} alt={icon.alt} />
      </figure>

      <div className="px-2">
        {renderContent()}
      </div>
    </article>
  );
}

interface BenefitCardsSectionProps {
  renderTitle: () => JSX.Element
  cards: BenefitCardProps[]
}

export function BenefitCardsSection({ renderTitle, cards }: BenefitCardsSectionProps) {
  return (
    <section>
      <header className="mb-3">
        {renderTitle()}
      </header>

      <div className="row g-3">
        {cards.map((card) => (
          <div key={card.id} className="col-4">
            <BenefitCard {...card} />
          </div>
        ))}
      </div>
    </section>
  );
}
