import React, { Fragment } from 'react';
import {
  BarChart, ResponsiveContainer, XAxis, YAxis,
} from 'recharts';

import { StackedBarChartConfig } from 'app/utils/types';
import clsx from 'clsx';
import { StackBar } from './StackBar';

interface HorizontalStackBarChartProps {
  chartData: StackedBarChartConfig
  labelCol?: number
  renderJoin?: (colWidthClassName: string) => JSX.Element
}

export function HorizontalStackBarChart({
  chartData,
  labelCol,
  renderJoin,
}: HorizontalStackBarChartProps) {
  return (
    <div>
      <div className="d-flex mt-2 px-1">
        {chartData.stacks.map((stack) => {
          if (!stack.value || !stack.topLabel) return null;

          return (
            <div
              key={stack.field}
              style={{
                width: `${stack.value}%`,
              }}
              className={clsx('text-center')}
            >
              {stack.topLabel(stack.color)}
            </div>
          );
        }
        )}
      </div>

      <ResponsiveContainer width="100%" height={54}>
        <BarChart
          data={[chartData]}
          layout="vertical"
        >
          {/*
            Axes are needed to allow chart rendering
            When layout is not `horizontal`, we need to explicitly
            define axes and its types
          */}

          {/* `domain` property needed for always take full width with chart */}
          <XAxis type="number" domain={[0, 'dataMax']} hide />
          <YAxis type="category" hide />
          {
            chartData.stacks.map((stack, index) => StackBar({
              layout: 'vertical',
              withInternalLabel: false,
              stackId: chartData.name,
              isFirst: index === 0,
              isLast: index === chartData.stacks.length - 1,
              borderRadius: 8,
              stack,
            }))
          }
        </BarChart>
      </ResponsiveContainer>

      <div className="row justify-content-center mt-1 px-1">
        {chartData.stacks.map((stack, index) => {
          // Each join component will occupy `2` bootstrap columns
          const JOINER_WIDTH = 2;
          // Base columns amount available by Bootstrap are `12`
          const BASE_COLUMNS_AMOUNT = 12;

          const isLastStack = index === chartData.stacks.length - 1;
          // We compute the available columns amount verifying if `renderJoin` is defined.
          // If it is, we need to substract the amount of columns that will be occupied
          // by "joiners" components.
          // The total amount of columns occupied by joiners are computed by the formula:
          // `(chartData.stacks.length - 1) * JOINER_WIDTH`, we just multiply the amount of
          // chart stacks by the joiner width, but omitting the last joiner
          // (because the last stack must not render its joiner)
          const totalJoinersColumnsAmount = (chartData.stacks.length - 1) * JOINER_WIDTH;
          const availableColumns = renderJoin
            ? BASE_COLUMNS_AMOUNT - totalJoinersColumnsAmount
            : BASE_COLUMNS_AMOUNT;

          const baseLabelWidth = labelCol ?? availableColumns / chartData.stacks.length;

          if (!stack.value) return null;

          return (
            <Fragment key={stack.field}>
              <div
                // Depending on number of stacks, calculates the column size for each one
                // based on Bootstrap columns grid (12 cols)
                className={clsx(`col-${baseLabelWidth}`)}
              >
                {stack.label(stack.color)}
              </div>
              {renderJoin && !isLastStack && renderJoin(`col-${JOINER_WIDTH}`)}
            </Fragment>
          );
        }
        )}
      </div>
    </div>
  );
}
