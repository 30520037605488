/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { getQueryParams } from 'app/utils/queryParams';
import { InfographContainerQueryParams, SimulationType } from 'app/utils/types';
import { getIcon } from 'app/utils/staticStorage';
import { CurrencyContextProvider } from 'app/contexts/CurrencyContext';
import { ProvisionalSavingsInfograph } from '../Infographs';
import { ApvLifeInfograph } from '../Infographs/ApvLifeInfograph';
import { FullFlexibleInfograph } from '../Infographs/FullFlexibleInfograph';
import { MutualFundsInfograph } from '../Infographs/MutualFundsInfograph';
import { InsuredUniversityInfograph } from '../Infographs/InsuredUniversityInfograph';

const infographRenderMap: Record<SimulationType, () => JSX.Element> = {
  APV: () => <div>Legacy APV</div>,
  APV_LIFE: () => <ApvLifeInfograph />,
  FULL_FLEXIBLE: () => <FullFlexibleInfograph />,
  INSURANCE_WITH_SAVINGS: () => <div>Insurance With Savings</div>,
  INSURED_UNIVERSITY: () => <InsuredUniversityInfograph />,
  MUTUAL_FUNDS: () => <MutualFundsInfograph />,
  PROVISIONAL_SAVINGS: () => <ProvisionalSavingsInfograph />,
};

const queryClient = new QueryClient();

export function InfographContainer() {
  const { infograph_type } = getQueryParams<InfographContainerQueryParams>();

  return (
    <QueryClientProvider client={queryClient}>
      <CurrencyContextProvider>
        <div className="infograph-container">
          <header className="mb-3">
            <figure className="p-3 m-0">
              <img src={getIcon('bice-logo.svg')} alt="Bice logo" />
            </figure>

            <div className="divider" />
          </header>

          <section>
            {infographRenderMap[infograph_type]()}
          </section>
        </div>
      </CurrencyContextProvider>
    </QueryClientProvider>
  );
}
