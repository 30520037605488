import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { FormatCurrencyOptions, formatCurrencyMap } from '../formatters';
import { transformCurrencyMap } from '../money';

export const withPrefix: FormatCurrencyOptions = {
  withPrefix: true,
};

/**
 *
 * Exposes a function for transform the amounts from some currency to another based on
 * the selected currency.
 *
 * This function should be used inside `CurrencyContext` tree to be allowed to capture
 * `currency` and `ufRatio` values
 */
export function useTransformCurrency(baseAmount: number, formatOptions?: FormatCurrencyOptions) {
  const { currency, ufRatio, defaultSimulationCurrency } = useCurrencyContext();

  /**
   * Theoretically `currency` should never be null because `useTransformCurrency` are called within
   * simulation content (after `currency` option has been initialized
   * by API response or query parameters)
   */
  if (!currency) {
    throw new Error('Cannot perform amount transformations without a target currency');
  }

  const transformedValue = currency === defaultSimulationCurrency
    ? baseAmount : transformCurrencyMap[currency](baseAmount, ufRatio);

  return formatCurrencyMap[currency](transformedValue, formatOptions);
}
