import React from 'react';
import clsx from 'clsx';

import { TextContainer } from 'app/utils/types';

type BlockTitleProps = TextContainer;

export function BlockTitle({
  primaryText, secondaryText, overwrittenClassName, extraClassName,
}: BlockTitleProps) {
  return (
    <h4 className={clsx(overwrittenClassName ?? 'fw-bold text-center', extraClassName)}>
      <span className={clsx('me-1', primaryText?.color ?? 'text-dark', primaryText?.extraClassName)}>
        {primaryText?.content}
      </span>
      <span className={clsx(secondaryText?.color ?? 'text-secondary', secondaryText?.extraClassName)}>
        {secondaryText?.content}
      </span>
    </h4>

  );
}
