import { getIcon } from 'app/utils/staticStorage';
import React from 'react';
import { Card } from './Card';

interface FieldDisplayerCardProps {
  renderText: () => JSX.Element
  renderFieldDisplayer: () => JSX.Element
  iconName?: string
  iconAlt?: string
}

export function FieldDisplayerCard({
  iconName, iconAlt = '', renderFieldDisplayer, renderText,
}: FieldDisplayerCardProps) {
  return (
    <Card extraClassName="h-100">
      <div className="d-flex gap-3 align-items-center h-100">
        {iconName && <img src={getIcon(iconName)} alt={iconAlt} />}

        <div className="d-flex flex-column gap-2 flex-grow-1 w-100">
          <p className="mb-0 fs-smaller">
            {renderText()}
          </p>

          {renderFieldDisplayer()}
        </div>

      </div>
    </Card>
  );
}
