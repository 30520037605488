/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { PolicyProjectionContainerQueryParams, SimulationTypeWithPolicyProjection } from 'app/utils/types';
import { getQueryParams } from 'app/utils/queryParams';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { APVLifePolicyProjection } from '../policyProjections';
import { InsuredUniversityPolicyProjection } from '../policyProjections/InsuredUniversityPolicyProjection';
import { FullFlexiblePolicyProjection } from '../policyProjections/FullFlexiblePolicyProjection';

const policyProjectionRenderMap: Record<SimulationTypeWithPolicyProjection, () => JSX.Element> = {
  INSURED_UNIVERSITY: () => <InsuredUniversityPolicyProjection />,
  APV_LIFE: () => <APVLifePolicyProjection />,
  FULL_FLEXIBLE: () => <FullFlexiblePolicyProjection />,
};

const queryClient = new QueryClient();

export function PolicyProjectionContainer() {
  const { simulation_type } = getQueryParams<PolicyProjectionContainerQueryParams>();

  return (
    <QueryClientProvider client={queryClient}>
      {policyProjectionRenderMap[simulation_type as SimulationTypeWithPolicyProjection]()}
    </QueryClientProvider>
  );
}
