interface PublicEnv {
  BASE_STORAGE_URL: string,
  INFOGRAPH_CTA_URL: string,
  LIFEPLAN_HOME_URL: string
}

type PublicEnvKey = keyof PublicEnv;

const PUBLIC_ENV_ELEMENT_ID = '#public_env';
const publicEnv = document.querySelector(PUBLIC_ENV_ELEMENT_ID);

export const getEnv = (key: PublicEnvKey) => {
  if (!publicEnv) return null;

  const parsedData = JSON.parse(publicEnv.textContent || '') as PublicEnv;

  return parsedData[key];
};
