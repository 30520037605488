import { getIcon } from 'app/utils/staticStorage';
import React from 'react';

export function Footer() {
  return (

    <footer className="d-none d-lg-block py-5 border-top">
      <div className="d-flex flex-column px-5 mx-5 flex-xl-row justify-content-xl-between align-items-center gap-5">
        <nav
          className="nav align-items-center gap-5 order-last order-xl-first"
          aria-label="Otros productos BICE"
        >
          <a className="nav-link p-0" href="https://banco.bice.cl/inversiones/" target="_blank" rel="noreferrer">
            <img src={getIcon('bice-inversiones-logo.svg')} alt="BICE Inversiones" />
          </a>
          <a className="nav-link p-0" href="https://www.bicevida.cl/" target="_blank" rel="noreferrer">
            <img src={getIcon('bice-vida-logo.svg')} alt="BICE Vida" />
          </a>
          <a className="nav-link p-0" href="https://banco.bice.cl/personas" target="_blank" rel="noreferrer">
            <img src={getIcon('banco-bice-logo.svg')} alt="Banco BICE" />
          </a>
        </nav>

        <nav className="nav align-items-center gap-4" aria-label="Redes sociales">
          <a
            className="nav-link p-0"
            href="https://www.facebook.com/BICELifePlan"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={getIcon('circle-facebook.svg')}
              alt="Facebook"
              height="32px"
            />
          </a>
          <a
            className="nav-link p-0"
            href="https://www.instagram.com/bicelifeplan/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={getIcon('circle-instagram.svg')}
              alt="Instagram"
              height="32px"
            />
          </a>
          <a
            className="nav-link p-0"
            href="https://www.linkedin.com/company/bice-lifeplan/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={getIcon('circle-linkedin.svg')}
              alt="Linkedin"
              height="32px"
            />
          </a>
          <a
            className="nav-link p-0"
            href="https://www.youtube.com/channel/UCFU77vCO9a48YuDZoJzd_Aw"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={getIcon('circle-youtube.svg')}
              alt="Youtube"
              height="32px"
            />
          </a>
        </nav>
      </div>
    </footer>

  );
}
