export const SIMULATION_ENDPOINTS = {
  MUTUAL_FUNDS: '/public/mutual-funds-simulation/',
  APV: '/public/apv-simulation/',
  INSURANCE_WITH_SAVINGS: '/public/insurance-with-apv/',
  SAVINGS_PROJECTION: '/public/apv-projection/',
  PROVISIONAL_SAVINGS: '/public/apv-afp-projection/',
  APV_LIFE: '/public/apv-life/',
  FULL_FLEXIBLE: '/public/full-flexible/',
  INSURED_UNIVERSITY: '/public/insured-university/',
};
