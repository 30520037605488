/* eslint-disable react/no-array-index-key */
import React from 'react';
import { SIMULATION_NAMES } from 'app/utils/constants';

interface PolicyKeypointsListProps {
  add_apv: boolean
}

function PolicyKeypointsList({ add_apv }: PolicyKeypointsListProps) {
  const POLICY_KEYPOINTS = [
    'Las primas se pagan a comienzo de cada periodo según periodicidad de pago.',
    'Los valores en la proyección corresponden al final de cada año. La rentabilidad exhibida tiene como objeto sólo realizar una ilustración particular, la que no se relaciona con la rentabilidad obtenida por algún instrumento específico.',
  ];

  const APV_KEYPOINTS = [
    'Los montos de rescate indicados son brutos. El monto líquido a pago de los retiros dependerá del Régimen Tributario en el que estés:',
    'Los valores de rescate no son aplicables a aportes realizados por conceptos de depósitos convenidos.',
    'Régimen A: Se descontará de tu Cuenta de bonificación Fiscal el 15% del monto que retires.',
    'Régimen B: La compañía retendrá un 15% del monto retirado.',
  ];

  const KEYPOINTS = add_apv ? POLICY_KEYPOINTS.concat(APV_KEYPOINTS) : POLICY_KEYPOINTS;

  return (
    <div>
      <h3 className="fw-bold">Bases de la Ilustración: </h3>
      <ul>
        {KEYPOINTS.map((policy, index) => <li key={index}>{policy}</li>)}
      </ul>
    </div>
  );
}

function PolicyNotesList() {
  const POLICY_NOTES = [
    'Esta simulación es sólo referencial, por lo que no constituye una cotización.',
  ];

  return (
    <div>
      <h3 className="fw-bold">Notas:</h3>
      <ul>
        {POLICY_NOTES.map((note, index) => <li key={index}>{note}</li>)}
      </ul>
    </div>
  );
}

interface PolicyHelpSectionProps {
  title: string
}

export function PolicyHelpSection({ title }: PolicyHelpSectionProps) {
  const NON_APV_SIMULATIONS = [
    SIMULATION_NAMES.FULL_FLEXIBLE,
    SIMULATION_NAMES.INSURED_UNIVERSITY,
  ];

  const add_apv = !NON_APV_SIMULATIONS.includes(title);

  return (
    <section id="policy-help-section" className="fs-base text-primary w-75">
      <div className="mb-4">
        <PolicyKeypointsList add_apv={add_apv} />
      </div>
      <div className="mb-4">
        <PolicyNotesList />
      </div>
    </section>

  );
}
