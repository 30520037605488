import { getWording } from 'app/constants/wording/wording';
import { getQueryParams } from 'app/utils/queryParams';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { StackedBarChartConfig } from 'app/utils/types';
import { themeColors } from 'app/utils/colors';
import { DotDecoration } from 'app/components/common';
import { getPercentage } from 'app/utils/charts';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import { InsuredUniversityPayload, insuredUniversityService } from './insuredUniversityService';

export function useInsuredUniversity() {
  const [showEmptyContent, setShowEmptyContent] = useState<boolean>(false);
  const { is_empty, ...payloadParameters } = getQueryParams<InsuredUniversityPayload>();
  const { wording } = getWording();
  const { handleInitCurrencyData } = useCurrencyContext();

  const {
    data: simulationResult,
    mutate: dispatchInsuredUniversitySimulation,
    isError,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async (payload: InsuredUniversityPayload) => (await insuredUniversityService
      .simulate(payload)).data,

    onSuccess: (data) => {
      const { currency, uf_value } = data;
      handleInitCurrencyData(currency, uf_value);
    },

  });

  const chartData = useMemo(() => {
    if (!simulationResult) return null;

    const { total_savings_interest, total_personal_savings_without_interest } = simulationResult;
    const total = total_savings_interest + total_personal_savings_without_interest;

    const chartConfig: StackedBarChartConfig = {
      name: 'INSURED_UNIVERSITY',
      stacks: [
        {
          field: 'savings',
          color: themeColors.info,
          value: getPercentage(total_personal_savings_without_interest, total),
          topLabel: () => <div>💵</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <div className="pb-1">
                  <DotDecoration size={8} color={color} />
                </div>
                <span className="fw-bold d-block" style={{ color }}>{r(total_personal_savings_without_interest, { withPrefix: true })}</span>
              </div>
              <div className="fs-smaller text-dark">
                <span className="text-capitalize">{`${wording.aported} `}</span>
                <span>para ahorro</span>
              </div>
            </div>
          ),
        },
        {
          field: 'rentability',
          color: themeColors.secondary,
          value: getPercentage(total_savings_interest, total),
          topLabel: () => <div>📈</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <div className="pb-1">
                  <DotDecoration size={8} color={color} />
                </div>
                <span className="fw-bold" style={{ color }}>{r(total_savings_interest, { withPrefix: true })}</span>
              </div>

              <div className="fs-smaller text-dark">
                <span className="text-capitalize">{`${wording.couldWin} `}</span>
                <span>ganar por rentabilidad</span>
              </div>
            </div>
          ),
        },
      ],
    };

    return chartConfig;
  }, [simulationResult]);

  useEffect(() => {
    if (is_empty) {
      setShowEmptyContent(Boolean(is_empty));
    }

    dispatchInsuredUniversitySimulation(payloadParameters);
  }, []);

  return {
    isError,
    isLoading,
    error,
    simulationResult,
    chartData,
    isEmpty: showEmptyContent,
  };
}
