import React from 'react';
import { getIcon } from 'app/utils/staticStorage';
import { getQueryParams } from 'app/utils/queryParams';
import { InfographContainerQueryParams } from 'app/utils/types';
import { BenefitCardsSection, GreetingsHeader, HelpCardsSection } from './components';
import { MutualFunds } from '../simulations';
import { HelpCardProps } from './components/HelpCardsSection';
import { BenefitCardProps } from './components/BenefitCardsSection';
import { CommonDisclaimers, Footer } from './components/Footer';

const helpCards: HelpCardProps[] = [
  {
    title: '¿Qué es LifePLan?',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>
          {'Nace con el objetivo de que encuentres, en las distintas etapas de tu vida '}
        </span>
        <span className="fw-bold">
          alternativas de ahorro y protección para disfrutar tu futuro con la
          tranquilidad y seguridad.
        </span>
      </p>
    ),
  },
  {
    title: '¿Qué son los Fondos Mutuos',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>{'Son un '}</span>
        <span className="fw-bold">{'mecanismo de inversión '}</span>
        <span>{'que se '}</span>
        <span className="fw-bold">{'ajusta a tus objetivos de inversión, perfil de riesgo y plazos de ahorro '}</span>
        <span>con el fin de que obtengas rentabilidad.</span>
      </p>
    ),
  },
];

const benefitCards: BenefitCardProps[] = [
  {
    id: 'advisory',
    icon: {
      alt: 'globe messages icon',
      src: getIcon('globe-messages.svg'),
    },
    renderContent: () => (
      <p className="text-primary small text-center">
        <span>{'Cuentas con una '}</span>
        <span className="fw-bold">{'asesoría personalizada, '}</span>
        <span>si tienes alguna duda.</span>
      </p>
    ),
  },
  {
    id: 'location',
    icon: {
      src: getIcon('earth-globe.svg'),
      alt: 'Earth globe icon',
    },
    renderContent: () => (
      <p className="text-primary small text-center">
        <span className="fw-bold">{'Puedes invertir '}</span>
        <span>en Chile como en el extranjero.</span>
      </p>
    ),
  },
  {
    id: 'diversity',
    icon: {
      src: getIcon('stats.svg'),
      alt: 'Stats icon',
    },
    renderContent: () => (
      <p className="text-primary small text-center">
        <span>{'Cuentas con distintos '}</span>
        <span className="fw-bold">fondos de inversión a tu medida.</span>
      </p>
    ),
  },
];

export function MutualFundsInfograph() {
  const { customer_name } = getQueryParams<InfographContainerQueryParams>();

  return (
    <div className="h-100">
      <div className="px-3">
        <GreetingsHeader customerName={customer_name} />
      </div>

      <div className="d-flex justify-content-center">
        <MutualFunds hideOuterHeader containerClassname="px-3 mb-3" containerStyle={{ width: '95%' }} />
      </div>

      <div>
        <HelpCardsSection
          title="Te ayudamos a entender"
          cards={helpCards}
        />
      </div>

      <div className="p-3">
        <BenefitCardsSection
          renderTitle={() => (
            <h3 className="fs-6 text-primary fw-bold text-center">
              <span>{'¿Cuáles son los beneficios de contratar '}</span>
              <span className="text-secondary">Fondos Mutuos</span>
              <span>?</span>
            </h3>
          )}
          cards={benefitCards}
        />
      </div>

      <Footer>
        <CommonDisclaimers />
      </Footer>
    </div>
  );
}
