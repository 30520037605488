import React from 'react';

interface BlockProps {
  className?: string
  children: JSX.Element | JSX.Element[] | null
}

export function Block({ className, children }: BlockProps) {
  return (
    <div className={className}>
      { children }
    </div>
  );
}
