import React from 'react';

interface GreetingsHeaderProps {
  customerName: string
}

export function GreetingsHeader({ customerName }: GreetingsHeaderProps) {
  return (
    <header className="d-flex flex-column gap-3 px-4 mb-3">
      <h2 className="fs-6 fw-bold text-primary mb-0">
        {`Hola ${customerName}:`}
      </h2>

      <h3 className="fs-base text-primary mb-0">
        Este es el resultado de la simulación que hiciste anteriormente:
      </h3>
    </header>
  );
}
