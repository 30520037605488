// Utilities for charts manipulation
import { BorderRadiusTuples, StackBarChartLayouts } from './types';

// Generate border radius array tuple for first and last stacked bar in charts
export const generateBorderRadius = (
  borderRadius: number,
  layout: StackBarChartLayouts
): BorderRadiusTuples => {
  const radiusByLayoutMap: Record<StackBarChartLayouts, BorderRadiusTuples> = {
    horizontal: {
      roundedFirst: [0, 0, borderRadius, borderRadius],
      roundedLast: [borderRadius, borderRadius, 0, 0],
    },
    vertical: {
      roundedFirst: [borderRadius, 0, 0, borderRadius],
      roundedLast: [0, borderRadius, borderRadius, 0],
    },
  };

  return radiusByLayoutMap[layout];
};

export const getPercentage = (target: number, total: number) => (target * 100) / total;

interface CreateCostsAndSavingsDataPairsOptions {
  costs: number[]
  savings: number[]
  interval: number
}

export const divideArrayByIntervals = (arr: unknown[], interval: number) => arr
  .filter((_, index) => index === 0 || index % interval === 0);

export const createCostsAndSavingsDataPairs = ({
  costs, interval, savings,
}: CreateCostsAndSavingsDataPairsOptions) => {
  const costsPairs = divideArrayByIntervals(costs, interval).map((cost, index) => (
    {
      year: index === 0 ? 1 : index * interval,
      amount: cost,
    }
  ));

  const savingsPairs = divideArrayByIntervals(savings, interval).map((saving, index) => (
    {
      year: index === 0 ? 1 : index * interval,
      amount: saving,
    }
  ));

  return { costsPairs, savingsPairs };
};
