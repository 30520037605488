import React from 'react';

interface DotDecorationProps {
  size: number
  color: string
}

export function DotDecoration({ size, color }: DotDecorationProps) {
  return <div className="rounded-circle d-inline-block" style={{ width: size, height: size, backgroundColor: color }} />;
}
