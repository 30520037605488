import React, { useEffect, useMemo, useState } from 'react';
import { getQueryParams } from 'app/utils/queryParams';
import { getWording } from 'app/constants/wording/wording';
import { useMutation } from '@tanstack/react-query';
import { themeColors } from 'app/utils/colors';
import { StackedBarChartConfig } from 'app/utils/types';
import { DotDecoration } from 'app/components/common';
import { getPercentage } from 'app/utils/charts';

import { useTransformCurrency as r, withPrefix } from 'app/utils/hooks/useTransformCurrency';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { FullFlexiblePayload, fullFlexibleService } from './fullFlexibleService';

export function useFullFlexible() {
  const [showEmptyContent, setShowEmptyContent] = useState<boolean>(false);
  const { is_empty, ...payloadParameters } = getQueryParams<FullFlexiblePayload>();
  const { wording } = getWording();
  const { handleInitCurrencyData } = useCurrencyContext();

  const {
    data: simulationResult,
    mutate: dispatchFullFlexibleSimulation,
    isError,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async (payload: FullFlexiblePayload) => (await fullFlexibleService
      .simulate(payload)).data,

    onSuccess: (data) => {
      const { currency, uf_value } = data;
      handleInitCurrencyData(currency, uf_value);
    },
  });

  const chartData = useMemo(() => {
    if (!simulationResult) return null;

    const { total_personal_savings_without_interest, total_savings_interest } = simulationResult;
    const total = total_personal_savings_without_interest + total_savings_interest;

    const chartConfig: StackedBarChartConfig = {
      name: 'FULL_FLEXIBLE',
      stacks: [
        {
          field: 'savings',
          color: themeColors.info,
          value: getPercentage(total_personal_savings_without_interest, total),
          topLabel: () => <div>💵</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <div className="pb-1">
                  <DotDecoration size={8} color={color} />
                </div>
                <span className="fw-bold d-block" style={{ color }}>{r(total_personal_savings_without_interest, withPrefix)}</span>
              </div>

              <div className="fs-smaller text-dark">
                <span className="text-capitalize">{`${wording.aported} `}</span>
                para ahorro
              </div>
            </div>
          ),
        },
        {
          field: 'rentability',
          color: themeColors.secondary,
          value: getPercentage(total_savings_interest, total),
          topLabel: () => <div>📈</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <div className="pb-1">
                  <DotDecoration size={8} color={color} />
                </div>
                <span className="fw-bold" style={{ color }}>{r(total_savings_interest, withPrefix)}</span>
              </div>

              <div className="fs-smaller text-dark">
                <span className="text-capitalize">{`${wording.couldWin} `}</span>
                ganar por rentabilidad
              </div>
            </div>
          ),
        },
      ],
    };

    return chartConfig;
  }, [simulationResult]);

  useEffect(() => {
    if (is_empty) {
      setShowEmptyContent(Boolean(is_empty));
    }

    dispatchFullFlexibleSimulation(payloadParameters);
  }, []);

  return {
    isError,
    isLoading,
    error,
    simulationResult,
    chartData,
    isEmpty: showEmptyContent,
  };
}
