import { themeColors } from 'app/utils/colors';
import { getQueryParams } from 'app/utils/queryParams';
import { StackedBarChartConfig } from 'app/utils/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getInsuranceWithSavingsWording } from 'app/constants/wording/insuranceWithSavings';
import { getPercentage } from 'app/utils/charts';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r, withPrefix } from 'app/utils/hooks/useTransformCurrency';
import {
  InsuranceWithSavingsPayload, InsuranceWithSavingsRegime, insuranceWithSavingsService,
} from './insuranceWithSavingsService';

export function useInsuranceWithSavings() {
  const [showEmptyContent, setShowEmptyContent] = useState<boolean>(false);
  const [simulationResult, setSimulationResult] = useState<InsuranceWithSavingsRegime>();
  const { is_empty, ...payloadParameters } = getQueryParams<InsuranceWithSavingsPayload>();
  const { wording } = getInsuranceWithSavingsWording();
  const { handleInitCurrencyData } = useCurrencyContext();

  const {
    mutate: dispatchInsuranceWithSavingsSimulation,
    isError,
    isLoading,
  } = useMutation({
    mutationFn: async (payload: InsuranceWithSavingsPayload) => {
      const rawResponse = await insuranceWithSavingsService.simulate(payload);
      const { data } = rawResponse;
      return {
        regime: data.recommended_regime,
        data: data[data.recommended_regime],
        currency: data.currency,
        uf_value: data.uf_value,
      };
    },

    onSuccess: ({
      data, regime, currency, uf_value,
    }) => {
      handleInitCurrencyData(currency, uf_value);
      setSimulationResult({ ...data, regime });
    },
  });

  const chartData = useMemo(() => {
    if (!simulationResult) {
      return null;
    }

    const {
      fiscal_contribution_without_interest,
      savings_expected_return,
      total_additional_savings,
      contributions_from_plan_without_interest,
      regime,
    } = simulationResult;

    const TOTAL_SUM = savings_expected_return
      + total_additional_savings
      + contributions_from_plan_without_interest
      + fiscal_contribution_without_interest;

    const chartConfig: StackedBarChartConfig = {
      name: 'APV',
      stacks: [
        {
          field: 'rentability',
          color: themeColors.secondary,
          value: getPercentage(savings_expected_return, TOTAL_SUM),
          label: (color) => (
            <div style={{ color }}>
              <span className="fw-bold">{`📊 ${r(savings_expected_return, withPrefix)} `}</span>
              <span>{`${wording.willWin} por la rentabilidad.`}</span>
            </div>
          ),
        },
        {
          field: 'additional-savings',
          color: themeColors.orange,
          value: getPercentage(total_additional_savings, TOTAL_SUM),
          label: (color) => (
            <div style={{ color }}>
              <span className="fw-bold">{`💵 ${r(total_additional_savings, withPrefix)} `}</span>
              <span>{`es ${wording.your} ahorro adicional.`}</span>
            </div>
          ),
        },
        {
          field: 'savings',
          color: themeColors.pink,
          value: getPercentage(contributions_from_plan_without_interest, TOTAL_SUM),
          label: (color) => (
            <div style={{ color }}>
              <span className="fw-bold me-1">{`💵 ${r(contributions_from_plan_without_interest, withPrefix)}`}</span>
              <span>{`${wording.willSaveInPlan} en ${wording.your} Plan.`}</span>
            </div>
          ),
        },
        {
          field: 'bonuses',
          color: themeColors.purple,
          value: getPercentage(fiscal_contribution_without_interest, TOTAL_SUM),
          label: (color) => (
            <div style={{ color }}>
              <span className="fw-bold">{`📊 ${r(fiscal_contribution_without_interest, withPrefix)} `}</span>
              <span>
                {
                  regime === 'A'
                    ? `${wording.suggestion} regalará el estado en bonos.` : 'el estado podría reducir en impuestos.'
                }
              </span>
            </div>
          ),
        },
      ],
    };

    return chartConfig;
  }, [simulationResult]);

  useEffect(() => {
    if (is_empty) {
      setShowEmptyContent(Boolean(is_empty));
      return;
    }

    dispatchInsuranceWithSavingsSimulation(payloadParameters);
  }, []);

  return {
    chartData,
    isError,
    isLoading,
    simulationResult,
    isEmpty: showEmptyContent,
  };
}
