/* eslint-disable react/require-default-props */
import React from 'react';

import { getCurrentYear } from 'app/utils/dates';
import { RegimeLiteral } from 'app/utils/types';
import { getAPVWording } from 'app/constants/wording/apv';

import { useTransformCurrency as r, withPrefix } from 'app/utils/hooks/useTransformCurrency';

interface BoxProps {
  amountMessage: string,
  message: string
  backgroundClassName?: string
}

function Box({ amountMessage, message, backgroundClassName }: BoxProps) {
  return (
    <div className={`p-3 box small d-flex justify-content-center align-items-center text-center ${backgroundClassName || ''}`}>
      <div>
        <span className="d-block">{message}</span>
        <span className="text-primary fw-bold">
          {amountMessage}
        </span>
      </div>
    </div>

  );
}

interface SavingsCardsProps {
  totalSavings: number
  currentYearSavings: number
  currentYearBenefit: number
  regime: RegimeLiteral
}
export function SavingsCards({
  currentYearBenefit,
  currentYearSavings,
  totalSavings,
  regime,
}: SavingsCardsProps) {
  const { wording } = getAPVWording();

  const bonusTextByRegime: Record<RegimeLiteral, string> = {
    a: `El estado ${wording.suggestion} premia con`,
    b: `Se reducirá el pago de ${wording.yours} impuestos en`,
  };

  return (
    <section className="mb-3 mb-md-4">
      <div className="simulation-result-cards">
        <div className="w-100">
          <div className="card card-box arrow">
            <div className="p-3 p-md-2 summation-boxes">

              <Box
                backgroundClassName="bg-light"
                amountMessage={`${r(currentYearSavings, withPrefix)} 💵`}
                message={`De ${wording.your} bolsillo saldrán`}
              />

              {/* DIVIDER */}
              <span className="fs-1 text-primary align-self-center">+</span>

              <Box
                amountMessage={`${r(currentYearBenefit, withPrefix)} 🎁`}
                message={bonusTextByRegime[regime]}
              />
            </div>
          </div>
        </div>

        <div className="w-100 card card-box">
          <div className="h-100 d-flex align-items-center">
            <div className="px-5 px-md-3_5 py-3 py-md-2 text-center small">
              <span className="d-block px-3 px-md-0">
                {`En ${getCurrentYear()} ${wording.willSave} ahorrado en APV`}
              </span>
              <span className="text-primary fw-bold">
                {`${r(totalSavings, withPrefix)} 💰`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}
