import React from 'react';

interface LoadingSpinnerProps {
  color: string,
  size: number
  borderWidth: number
}

export function LoadingSpinner({
  color = 'light', size = 22, borderWidth = 3,
}: Partial<LoadingSpinnerProps>) {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className={`spinner-border text-${color}`} role="status" style={{ width: size, height: size, borderWidth }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
