import dayjs from 'dayjs';

const YEAR_MONTHS_AMOUNT = 12;

export const generateDateFromTextAge = (textAge: number) => dayjs().subtract(textAge, 'year').format('YYYY-MM-DD');
export const getCurrentYear = () => dayjs().year();

export const computeTimeFrame = (rawTimeFrame: number) => {
  const rawYears = Math.floor(rawTimeFrame / YEAR_MONTHS_AMOUNT);
  const months = Math.ceil(rawTimeFrame % YEAR_MONTHS_AMOUNT);

  // Transforms 12 months into 1 year
  const years = months === 12 ? rawYears + 1 : rawYears;

  const hasYears = years >= 1;
  // Months will be shown only if amount is between 1 and 11
  const hasMonths = months >= 1 && months < 12;

  return {
    years,
    months,
    hasMonths,
    hasYears,
  };
};

export const transformMonthsToYears = (months: number) => {
  const years = Math.floor(months / YEAR_MONTHS_AMOUNT);

  return years;
};
