import React from 'react';
import { getIcon } from 'app/utils/staticStorage';
import { currencyFormatter } from 'app/utils/formatters';
import { getQueryParams } from 'app/utils/queryParams';
import { InfographContainerQueryParams } from 'app/utils/types';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { GreetingsHeader } from './components';
import { ApvLife } from '../simulations/apvLife/ApvLife';
import { HelpCardProps, HelpCardsSection } from './components/HelpCardsSection';
import { BenefitCardsSection } from './components/BenefitCardsSection';
import { Footer, PrivacyDisclaimer } from './components/Footer';
import { ApvLifePayload } from '../simulations/apvLife/apvLifeService';

const helpCards: HelpCardProps[] = [
  {
    title: '¿Qué es LifePLan?',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>
          {'Nace con el objetivo de que encuentres, en las distintas etapas de tu vida '}
        </span>
        <span className="fw-bold">
          alternativas de ahorro y protección para disfrutar tu futuro con la
          tranquilidad y seguridad.
        </span>
      </p>
    ),
  },
  {
    title: '¿Qué es el Ahorro Previsional Voluntario (APV)?',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>{'Es un '}</span>
        <span className="fw-bold">{'ahorro adicional '}</span>
        <span>{'para tu pensión '}</span>
        <span className="fw-bold">{'donde obtendrás beneficios tributarios y podrás aumentar el monto de tu pensión '}</span>
        <span>o compensar períodos no cotizados.</span>
      </p>
    ),
  },
];

const benefitCards = () => {
  const { death_insured_capital } = getQueryParams<ApvLifePayload>();
  const { ufRatio } = useCurrencyContext();

  const insuredCapital = death_insured_capital * ufRatio;

  return [
    {
      id: 'increasable',
      icon: {
        alt: 'wallet icon',
        src: getIcon('wallet.svg'),
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span className="fw-bold">{'Aumenta o adelanta tu pensión '}</span>
          <span>con inversiones en distintos fondos.</span>
        </p>
      ),
    },
    {
      id: 'taxes',
      icon: {
        alt: 'money icon',
        src: getIcon('money.svg'),
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span className="fw-bold">{'Los beneficios del Estado '}</span>
          <span>incrementan tu ahorro o rebajan tus impuestos.</span>
        </p>
      ),
    },
    {
      id: 'insured',
      icon: {
        alt: 'shield icon',
        src: getIcon('shield.svg'),
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span className="fw-bold">{'Estarás asegurado '}</span>
          <span>{'con un capital de '}</span>
          <span className="fw-bold">{`${currencyFormatter.format(insuredCapital)} `}</span>
          <span>para tus beneficiarios.</span>
        </p>
      ),
    },
  ];
};

export function ApvLifeInfograph() {
  const { customer_name } = getQueryParams<InfographContainerQueryParams>();

  return (
    <div className="h-100">
      <div className="px-3">
        <GreetingsHeader customerName={customer_name} />
      </div>

      <div className="d-flex justify-content-center">
        <ApvLife containerClassname="px-3 mb-3" />
      </div>

      <div>
        <HelpCardsSection title="Te ayudamos a entender" cards={helpCards} />
      </div>

      <div className="p-3">
        <BenefitCardsSection
          renderTitle={() => (
            <h3 className="fs-6 text-primary fw-bold text-center">
              <span>{'¿Cuáles son los beneficios de contratar '}</span>
              <span className="text-secondary">APV Life</span>
              <span>?</span>
            </h3>
          )}
          cards={benefitCards()}
        />
      </div>

      <Footer>
        <PrivacyDisclaimer />
      </Footer>
    </div>
  );
}
