import React, { useEffect, useMemo, useState } from 'react';
import { getQueryParams } from 'app/utils/queryParams';
import { getWording } from 'app/constants/wording/wording';
import { useMutation } from '@tanstack/react-query';
import { StackedBarChartConfig } from 'app/utils/types';
import { themeColors } from 'app/utils/colors';
import { DotDecoration } from 'app/components/common';
import { getPercentage } from 'app/utils/charts';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r, withPrefix } from 'app/utils/hooks/useTransformCurrency';
import { ApvLifePayload, apvLifeService } from './apvLifeService';

export function useApvLife() {
  const [showEmptyContent, setShowEmptyContent] = useState<boolean>(false);
  const { is_empty, ...payloadParameters } = getQueryParams<ApvLifePayload>();
  const { handleInitCurrencyData } = useCurrencyContext();
  const { wording } = getWording();

  const {
    data: simulationResult,
    mutate: dispatchApvLifeSimulation,
    isError,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async (payload: ApvLifePayload) => (await apvLifeService
      .simulate(payload)).data,

    onSuccess: (data) => {
      const { currency, uf_value } = data;
      handleInitCurrencyData(currency, uf_value);
    },
  });

  const chartData = useMemo(() => {
    if (!simulationResult) {
      return null;
    }

    const {
      total_afp_savings,
      total_savings_interest,
      total_personal_savings_without_interest,
      total_fiscal_contribution_without_interest
    } = simulationResult;
    const total = total_afp_savings
      + total_personal_savings_without_interest
      + total_fiscal_contribution_without_interest
      + total_savings_interest;

    const {
      apv_regime, compensation_plan, apv_transfer_amount, ideal_pension,
    } = payloadParameters;

    const chartConfig: StackedBarChartConfig = {
      name: 'Apv Life',
      stacks: [
        {
          field: 'savings',
          color: themeColors.orange,
          value: getPercentage(total_afp_savings, total),
          topLabel: () => <div>💵</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <DotDecoration size={8} color={color} />
                <span className="fw-bold d-block" style={{ color }}>{r(total_afp_savings, withPrefix)}</span>
              </div>
              <div className="fs-smaller text-dark">
                <span>{`Es ${wording.your} ahorro `}</span>
                <span>{`en ${wording.your} cuenta obligatoria`}</span>
              </div>
            </div>
          ),
        },
        {
          field: 'apv-savings',
          color: themeColors.info,
          value: getPercentage(total_personal_savings_without_interest, total),
          topLabel: () => <div>💰</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <DotDecoration size={8} color={color} />
                <span className="fw-bold d-block" style={{ color }}>{r(total_personal_savings_without_interest, withPrefix)}</span>
              </div>
              <div className="fs-smaller text-dark">{`Es ${wording.your} ahorro en APV`}</div>
            </div>
          ),
        },
        {
          field: 'bonuses',
          color: themeColors.primary,
          value: getPercentage(total_fiscal_contribution_without_interest, total),
          topLabel: () => <div>🎁</div>,
          label: (color) => {
            let bonusesText;
            if (!Number(apv_transfer_amount) && ((apv_regime === 'A' && Number(ideal_pension)) || (apv_regime === 'A' && compensation_plan === 'A'))) {
              bonusesText = (
                <>
                  <span className="text-capitalize">{`${wording.suggestion} `}</span>
                  <span>bonificará el estado</span>
                </>
              );
            } else if ((apv_regime === 'B' || compensation_plan === 'B' || Number(apv_transfer_amount))) {
              bonusesText = (
                <>
                  <span>{`Es ${wording.your} `}</span>
                  <span>beneficio tributario</span>
                </>
              );
            }

            return (
              <div className="fs-small">
                <div className="d-flex align-items-center gap-1">
                  <DotDecoration size={8} color={color} />
                  <span className="fw-bold d-block" style={{ color }}>{r(total_fiscal_contribution_without_interest, withPrefix)}</span>
                </div>
                <div className="fs-smaller text-dark">
                  {bonusesText}
                </div>
              </div>
            );
          },
        },
        {
          field: 'rentability',
          color: themeColors.secondary,
          value: getPercentage(total_savings_interest, total),
          topLabel: () => <div>📈</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <DotDecoration size={8} color={color} />
                <span className="fw-bold" style={{ color }}>{r(total_savings_interest, withPrefix)}</span>
              </div>
              <div className="fs-smaller text-dark">
                {`Lo que ${wording.couldWin} ganar por rentabilidad`}
              </div>
            </div>
          ),
        },
      ],
    };

    return chartConfig;
  }, [simulationResult]);

  useEffect(() => {
    if (is_empty) {
      setShowEmptyContent(Boolean(is_empty));
      return;
    }

    dispatchApvLifeSimulation(payloadParameters);
  }, []);

  return {
    isError,
    error,
    isLoading,
    simulationResult,
    chartData,
    isEmpty: showEmptyContent,
  };
}
