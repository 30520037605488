import { currencyFormatter, numberFormatter } from 'app/utils/formatters';
import { getQueryParams } from 'app/utils/queryParams';
import { WithWording, Wording } from 'app/utils/types';

const CLIENT_WORDING = {
  outerHeader: 'Si comienzas hoy a ahorrar:',
  targetGoal: {
    primaryText: (amount: number) => `Para lograr tu meta de ${currencyFormatter.format(amount)}`,
    secondaryText: {
      time_frame: 'tendrás que ahorrar durante:',
      monthly_contribution: 'tendrás que ahorrar: ',
    },
  },
  chartHeader: 'Así se podría ver tu total ahorrado en Fondos Mutuos:',
  chartLabels: {
    rentability: 'podrías ganar por rentabilidad.',
    savings: 'saldrán de tu bolsillo.',
  },
  emptyContent: {
    fieldDisplayer: {
      primaryText: {
        time_frame: (amount: number) => numberFormatter.format(amount),
        monthly_contribution: (amount: number) => currencyFormatter.format(amount),
      },
      secondaryText: {
        time_frame: 'años',
        monthly_contribution: 'mensuales',
      },
    },
    chartPlaceholder: 'Aquí verás cómo logras tu meta',
  },
};

// We just replicate the keys structure from CLIENT map into EXECUTIVE map to keep type safety
// and guarantee both contains same keys and values
type ContentStructure = typeof CLIENT_WORDING;

const EXECUTIVE_WORDING: ContentStructure = {
  outerHeader: 'Si comienza hoy a ahorrar:',
  targetGoal: {
    primaryText: (amount: number) => `Para lograr su meta de ${currencyFormatter.format(amount)}`,
    secondaryText: {
      time_frame: 'tendrá que ahorrar durante:',
      monthly_contribution: 'tendrá que ahorrar: ',
    },
  },
  chartHeader: 'Así se podría ver su total ahorrado en Fondos Mutuos:',
  chartLabels: {
    rentability: 'podría ganar por rentabilidad.',
    savings: 'saldrán de su bolsillo.',
  },
  emptyContent: {
    fieldDisplayer: {
      primaryText: {
        time_frame: (amount: number) => numberFormatter.format(amount),
        monthly_contribution: (amount: number) => currencyFormatter.format(amount),
      },
      secondaryText: {
        time_frame: 'años',
        monthly_contribution: 'mensuales',
      },
    },
    chartPlaceholder: 'Aquí verás cómo logra su meta',
  },
};

const WORDING_MAP: Record<Wording, ContentStructure> = {
  CLIENT: CLIENT_WORDING,
  EXECUTIVE: EXECUTIVE_WORDING,
};

export const getMutualFundsWording = () => {
  const DEFAULT_WORDING = WORDING_MAP.CLIENT;
  const { wording: choosenWording } = getQueryParams<WithWording>();

  if (!choosenWording) {
    return { choosenWording: 'CLIENT', wording: DEFAULT_WORDING };
  }

  return { choosenWording, wording: WORDING_MAP[choosenWording] };
};
