import React from 'react';

import { Popover as BootstrapPopover, OverlayTrigger } from 'react-bootstrap';

import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';

interface PopoverProps {
  id: string
  trigger: OverlayTriggerType[]
  width: number
  placement?: Placement
  renderContent: () => JSX.Element
  renderTrigger: () => JSX.Element
}

export function Popover({
  id, trigger, width, placement, renderTrigger, renderContent,
}: PopoverProps) {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={(
        <BootstrapPopover id={id} style={{ width }}>
          <div>{renderContent()}</div>
        </BootstrapPopover>
      )}
    >
      {renderTrigger()}
    </OverlayTrigger>
  );
}
