import clsx from 'clsx';
import React, { ReactNode } from 'react';

interface CardProps {
  extraClassName?: string
  children: ReactNode
}

export function Card({ children, extraClassName }: CardProps) {
  return (
    <div className={clsx('bg-white p-3 card-shadow rounded-4', extraClassName)}>
      {children}
    </div>
  );
}
