import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { getQueryParams } from 'app/utils/queryParams';

import { StackedBarChartConfig, ResultingRegime } from 'app/utils/types';
import { themeColors } from 'app/utils/colors';
import { currencyFormatter } from 'app/utils/formatters';
import { generateDateFromTextAge } from 'app/utils/dates';
import { getAPVWording } from 'app/constants/wording/apv';
import { getPercentage } from 'app/utils/charts';
import {
  ApvSimulationPayload,
  ApvSimulationData,
  RegimeData,
  ApvProjectedSavingsPayload,
  apvService,
  ApvSimulationResponse,
  ProjectedSavingsResponse,
  RegimeLiteralMap,
} from './apvService';

interface SimulationResults {
  apv: ApvSimulationResponse,
  projectedSavings: ProjectedSavingsResponse,
  regime: ResultingRegime
}

export function useApv() {
  const [showEmptyContent, setShowEmptyContent] = useState<boolean>(false);
  const [simulationResult, setSimulationResult] = useState<Partial<SimulationResults>>({});
  const { is_empty, ...payloadParameters } = getQueryParams<ApvProjectedSavingsPayload>();
  const { wording } = getAPVWording();

  const defineResultingRegime = (regimeA: RegimeData, regimeB: RegimeData): ResultingRegime => {
    if (regimeA.monthly_benefit > regimeB.monthly_benefit) {
      return {
        regimeLiteral: 'a',
        regimeProperty: 'regime_a',
      };
    }

    return {
      regimeLiteral: 'b',
      regimeProperty: 'regime_b',
    };
  };

  const apvSimulationData = useMemo(() => {
    const { apv, projectedSavings, regime } = simulationResult;
    if (apv && projectedSavings && regime) {
      const {
        savings_expected_return,
        fiscal_contribution_without_interest,
        personal_savings_without_interest,
      } = projectedSavings;

      const totalSavings = personal_savings_without_interest
        + fiscal_contribution_without_interest
        + savings_expected_return;

      const mergedData: ApvSimulationData = {
        ...apv,
        regime,
        total_savings: totalSavings,
        pension_rise: projectedSavings.pension_rise,
      };

      return mergedData;
    }

    return null;
  }, [simulationResult]);

  const chartData = useMemo(() => {
    const { apv, projectedSavings, regime } = simulationResult;

    if (apv && projectedSavings && regime) {
      const {
        savings_expected_return,
        fiscal_contribution_without_interest,
        personal_savings_without_interest,
      } = projectedSavings;

      const TOTAL_SUM = savings_expected_return
        + personal_savings_without_interest
        + fiscal_contribution_without_interest;

      const chartConfig: StackedBarChartConfig = {
        name: 'APV',
        stacks: [
          {
            field: 'rentability',
            color: themeColors.secondary,
            value: getPercentage(savings_expected_return, TOTAL_SUM),
            label: (color) => (
              <div style={{ color }}>
                <span className="fw-bold">{`📈 ${currencyFormatter.format(savings_expected_return)} `}</span>
                <span>{`${wording.willWin} por la rentabilidad.`}</span>
              </div>
            ),
          },
          {
            field: 'savings',
            color: themeColors.pink,
            value: getPercentage(personal_savings_without_interest, TOTAL_SUM),
            label: (color) => (
              <div className="mt-4 mt-md-0" style={{ color }}>
                <span className="fw-bold me-1">{`💵 ${currencyFormatter.format(personal_savings_without_interest)}`}</span>
                <span>{`saldrán de ${wording.your} bolsillo.`}</span>
              </div>
            ),
          },
          {
            field: 'bonuses',
            color: themeColors.purple,
            value: getPercentage(fiscal_contribution_without_interest, TOTAL_SUM),
            label: (color) => (
              <div style={{ color }}>
                <span className="fw-bold d-block">{`📈 ${currencyFormatter.format(fiscal_contribution_without_interest)} `}</span>
                <span>
                  {
                    regime.regimeLiteral === 'a'
                      ? `${wording.suggestion} regalará el estado en bonos.` : 'el estado podría reducir en impuestos.'
                  }
                </span>
              </div>
            ),
          },
        ],
      };

      return chartConfig;
    }

    return null;
  }, [simulationResult]);

  const {
    mutate: dispatchProjectedSavingsSimulation,
    isError: isProjectedSavingsSimulationError,
    isLoading: isProjectedSavingsSimulationLoading,
  } = useMutation({
    mutationFn: async (payload: ApvProjectedSavingsPayload) => {
      const {
        apv_regime, age, gender, monthly_savings, net_income, risk_profile,
      } = payload;

      const actualGender = gender === 'OTHER' ? 'FEMALE' : gender;

      const response = await apvService.simulateProjectedSavings({
        apv_regime: apv_regime.toUpperCase(),
        date_of_birth: generateDateFromTextAge(age),
        gender: actualGender,
        monthly_savings,
        net_salary: net_income,
        risk_profile,
      });

      return response.data;
    },

    onSuccess: (response) => {
      setSimulationResult({ ...simulationResult, projectedSavings: response });
    },
  });

  const {
    mutate: dispatchApvSimulation, isError: isApvSimulationError, isLoading: isApvSimulationLoading,
  } = useMutation({
    mutationFn: async (payload: ApvSimulationPayload) => (
      await apvService.simulate(payload))
      .data,
    onSuccess: (response) => {
      const injectedRegime = payloadParameters.regime;

      const regime = injectedRegime
        ? RegimeLiteralMap[injectedRegime]
        : defineResultingRegime(response.regime_a, response.regime_b);

      setSimulationResult({ ...simulationResult, apv: response, regime });

      dispatchProjectedSavingsSimulation({
        ...payloadParameters,
        risk_profile: 'moderate',
        apv_regime: regime.regimeLiteral,
      });
    },
  });

  useEffect(() => {
    if (is_empty) {
      setShowEmptyContent(Boolean(is_empty));
      return;
    }

    dispatchApvSimulation(payloadParameters);
  }, []);

  return {
    apvSimulationData,
    chartData,
    isEmpty: showEmptyContent,
    isError: isApvSimulationError || isProjectedSavingsSimulationError,
    isLoading: isApvSimulationLoading || isProjectedSavingsSimulationLoading,
  };
}
