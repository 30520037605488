import { getIllustration } from 'app/utils/staticStorage';
import { SimulationProductError } from 'app/utils/types';
import { AxiosError } from 'axios';
import React from 'react';

type SimulationErrorProps = {
  error: AxiosError<SimulationProductError>;
};

export function SimulationError({ error }: SimulationErrorProps) {
  const ERROR_MSG_PREFIX = 'Value error, ';
  const productError = Object.values(error.response?.data.errors ?? [])?.[0];
  const trimmedPrefixMsg = productError.msg.split(ERROR_MSG_PREFIX)[1];

  return (
    <section className="d-flex flex-column gap-4 align-items-center p-3_5 bg-light text-center rounded-xl" style={{ width: 790 }}>
      <img src={getIllustration('desktop-error.svg')} width={170} alt="Error" />

      <h1 className="h3 text-primary fw-bold mb-0">
        ¡Lo sentimos!
        <br />
        No se ha podido simular correctamente
      </h1>

      {/*
        If product error message cannot be
        trimmed by prefix, just show `productError.msg`
      */}
      <p className="text-dark fs-6 mb-0">{trimmedPrefixMsg ?? productError.msg}</p>
    </section>
  );
}
