import React from 'react';
import { getIcon } from 'app/utils/staticStorage';
import { getQueryParams } from 'app/utils/queryParams';
import { InfographContainerQueryParams } from 'app/utils/types';
import { GreetingsHeader, HelpCardsSection, BenefitCardsSection } from './components';

import { HelpCardProps } from './components/HelpCardsSection';
import { BenefitCardProps } from './components/BenefitCardsSection';

import { ProvisionalSavings } from '../simulations/provisionalSavings/ProvisionalSavings';
import { CommonDisclaimers, Footer } from './components/Footer';

const helpCards: HelpCardProps[] = [
  {
    title: '¿Qué es LifePLan?',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>
          {'Nace con el objetivo de que encuentres, en las distintas etapas de tu vida '}
        </span>
        <span className="fw-bold">
          alternativas de ahorro y protección para disfrutar tu futuro con la
          tranquilidad y seguridad.
        </span>
      </p>
    ),
  },
  {
    title: '¿Qué es el Ahorro Previsional Voluntario (APV)?',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>{'Es un '}</span>
        <span className="fw-bold">{'ahorro adicional '}</span>
        <span>{'ahorro adicional para tu pensión '}</span>
        <span className="fw-bold">{'donde obtendrás beneficios tributarios y podrás aumentar el monto de tu pensión '}</span>
        <span>o compensar períodos no cotizados.</span>
      </p>
    ),
  },
];

const benefitCards: BenefitCardProps[] = [
  {
    id: 'location',
    icon: {
      src: getIcon('earth-globe.svg'),
      alt: 'Earth globe icon',
    },
    renderContent: () => (
      <p className="text-primary small text-center">
        <span className="fw-bold">{'Puedes invertir '}</span>
        <span>en Chile como en el extranjero.</span>
      </p>
    ),
  },
  {
    id: 'diversity',
    icon: {
      src: getIcon('stats.svg'),
      alt: 'Stats icon',
    },
    renderContent: () => (
      <p className="text-primary small text-center">
        <span>{'Cuentas con distintos '}</span>
        <span className="fw-bold">fondos de inversión a tu medida.</span>
      </p>
    ),
  },
  {
    id: 'stoppable',
    icon: {
      src: getIcon('money.svg'),
      alt: 'Money icon',
    },
    renderContent: () => (
      <p className="text-primary small text-center">
        <span>{'Puedes '}</span>
        <span className="fw-bold">{'pausar o suspender '}</span>
        <span>tu ahorro.</span>
      </p>
    ),
  },
];

export function ProvisionalSavingsInfograph() {
  const { customer_name } = getQueryParams<InfographContainerQueryParams>();

  return (
    <div className="h-100">
      <div className="px-3">
        <GreetingsHeader customerName={customer_name} />
      </div>

      <div className="d-flex justify-content-center">
        <ProvisionalSavings containerClassname="px-3 mb-3" />
      </div>

      <div>
        <HelpCardsSection title="Te ayudamos a entender" cards={helpCards} />
      </div>

      <div className="p-3">
        <BenefitCardsSection
          renderTitle={() => (
            <h3 className="fs-6 text-primary fw-bold text-center">
              <span>{'¿Cuáles son los beneficios de contratar '}</span>
              <span className="text-secondary">APV</span>
              <span>?</span>
            </h3>
          )}
          cards={benefitCards}
        />
      </div>

      <Footer>
        <CommonDisclaimers />
      </Footer>
    </div>
  );
}
