import React from 'react';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { Card } from './Card';
import { FieldDisplayer } from './FieldDisplayer';

interface CardChartProps {
  renderChart: () => JSX.Element
  cardClassName?: string
  amount?: number
  renderText?: () => JSX.Element
  renderFieldDisplayer?: () => JSX.Element
}

export function CardChart({
  amount, cardClassName, renderChart, renderText, renderFieldDisplayer,
}: CardChartProps) {
  const { currencyPrefix } = useCurrencyContext();

  return (
    <Card extraClassName={cardClassName}>
      {renderText && (
        <header className="mb-2">
          <h6 className="mb-0 small">
            {renderText()}
          </h6>
        </header>
      )}

      {renderFieldDisplayer && renderFieldDisplayer()}

      {amount && !renderFieldDisplayer && (
        <div className="mb-3">
          <FieldDisplayer
            extraClassName="fs-1 bg-light-secondary w-100 "
            primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
            secondaryText={{ content: r(amount), color: 'text-dark' }}
          />
        </div>
      )}

      <div>
        {renderChart()}
      </div>
    </Card>

  );
}
