/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Bar, LabelProps } from 'recharts';

import { generateBorderRadius } from 'app/utils/charts';
import { Stack, StackBarChartLayouts } from 'app/utils/types';
import { useMediaQuery, MEDIA_QUERIES } from 'app/utils/hooks/useMediaQuery';

interface StackBarLabelProps extends LabelProps {
  stack: Stack,
}

function StackBarLabel({ stack, ...labelProps }: StackBarLabelProps) {
  const isMobileWindow = useMediaQuery(MEDIA_QUERIES.MOBILE);
  // All sizes constants are defined in px
  const MOBILE_MAX_WIDTH = 180;
  const DESKTOP_MAX_WIDTH = 372;
  const MAX_HEIGHT = 100;
  const HORIZONTAL_OFFSET = 16;

  const { x, y, width } = labelProps;
  const { color, label } = stack;

  const startX = Number(x) + Number(width) + HORIZONTAL_OFFSET;
  const startY = Number(y);

  return (
    <foreignObject
      fill={color}
      x={startX}
      y={startY}
      width={isMobileWindow ? MOBILE_MAX_WIDTH : DESKTOP_MAX_WIDTH}
      height={MAX_HEIGHT}
    >
      {label(color)}
    </foreignObject>
  );
}

interface StackBarProps {
  stackId: string
  stack: Stack
  isFirst?: boolean
  isLast?: boolean
  withInternalLabel?: boolean
  layout?: StackBarChartLayouts
  borderRadius?: number
}

export function StackBar({
  stackId,
  stack,
  isFirst,
  isLast,
  withInternalLabel = true,
  layout = 'horizontal',
  borderRadius = 10,
}: StackBarProps) {
  const { value, color, field } = stack;
  const { roundedFirst, roundedLast } = generateBorderRadius(borderRadius, layout);
  let radiusTuple;

  if (isFirst) radiusTuple = roundedFirst;
  if (isLast) radiusTuple = roundedLast;

  return (
    <Bar
      key={field}
      radius={radiusTuple}
      isAnimationActive={false}
      dataKey={() => value}
      stackId={stackId}
      fill={color}
      label={withInternalLabel
        ? (labelProps) => <StackBarLabel stack={stack} {...labelProps} /> : undefined}
    />
  );
}
