import { api } from 'app/utils/api';
import { SIMULATION_ENDPOINTS } from 'app/utils/endpoints';
import { WithPolicyByYearData } from 'app/utils/hooks/useBuildTable';
import {
  Genders, WithCurrencyInfo, WithEmptyOption,
} from 'app/utils/types';

export interface InsuredUniversityPayload extends WithEmptyOption {
  date_of_birth: string
  gender: Genders
  beneficiary_date_of_birth: string
  rentability: number
  death_insured_capital: number
  itp_insured_capital: number
  serious_illness_insured_capital: number
}

export interface InsuredUniversityResponse extends WithCurrencyInfo, WithPolicyByYearData {
  agreed_premium: number
  beneficiary_age: number
  goal: number
  monthly_insurance_cost: number
  monthly_savings: number
  timeframe: number
  total_personal_savings_without_interest: number
  total_savings_interest: number
  total_insurance_cost: number
  average_monthly_cost: number
}

export const insuredUniversityService = {
  simulate: async (payload: InsuredUniversityPayload) => api
    .post<InsuredUniversityResponse>(SIMULATION_ENDPOINTS.INSURED_UNIVERSITY, payload),
};
