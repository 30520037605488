import React from 'react';
import { getWording } from 'app/constants/wording/wording';
import { BlockTitle, SummationBoxes } from 'app/components/common';
import { stringFormatter } from 'app/utils/formatters';
import { RegimeLiteral } from 'app/utils/types';

interface MonthlySavingsForRegimeAProps {
  monthly_benefit: number
  monthly_savings: number
  total_monthly_benefit: number
}

function MonthlySavingsForRegimeA({
  monthly_savings,
  monthly_benefit,
  total_monthly_benefit,
}: MonthlySavingsForRegimeAProps) {
  const { wording } = getWording();

  return (
    <>
      <header className="mb-3">
        <BlockTitle
          extraClassName="text-center mb-3"
          primaryText={{ content: '¿Por qué?', color: 'text-primary' }}
        />

        <p className="text-center text-dark">
          <span>{`Si ${wording.start} `}</span>
          <span className="fw-bold">{'hoy, al mes '}</span>
          <span>{`${wording.willGoTo} ahorrando:`}</span>
        </p>
      </header>

      <SummationBoxes
        boxes={[
          {
            id: 'savings',
            renderText: () => <span>{`De ${wording.your} bolsillo saldrán`}</span>,
            amount: monthly_savings,
          },
          {
            id: 'bonuses',
            renderText: () => <span>{`El estado ${wording.suggestion} premia con`}</span>,
            amount: monthly_benefit,
          },
        ]}
        totalBox={{
          id: 'total',
          renderText: () => <span>{`En un mes ${wording.willSave} ahorrado`}</span>,
          amount: total_monthly_benefit,
        }}
      />
    </>

  );
}

interface MonthlySavingsForRegimeBProps {
  monthly_savings: number
  monthly_personal_payment: number
  monthly_tax_deduction: number
}

function MonthlySavingsForRegimeB({
  monthly_personal_payment,
  monthly_savings,
  monthly_tax_deduction,
}: MonthlySavingsForRegimeBProps) {
  const { wording } = getWording();

  return (
    <>
      <header className="mb-3">
        <BlockTitle
          extraClassName="text-center mb-3"
          primaryText={{ content: '¿Por qué?', color: 'text-primary' }}
        />

        <p className="text-center text-dark">
          <span>{`Si ${wording.start} `}</span>
          <span className="fw-bold">{'hoy, al mes '}</span>
          <span>{`${wording.willGoTo} ahorrando:`}</span>
        </p>
      </header>

      <SummationBoxes
        reversed
        boxes={[
          {
            id: 'savings',
            renderText: () => <span>{`De ${wording.your} bolsillo saldrán`}</span>,
            amount: monthly_personal_payment,
          },
          {
            id: 'bonuses',
            renderText: () => <span>{`${stringFormatter.capitalize(wording.yours)} impuestos se reducen en`}</span>,
            amount: monthly_tax_deduction,
          },
        ]}
        totalBox={{
          id: 'total',
          renderText: () => <span>{`En un mes ${wording.willSave} ahorrado`}</span>,
          amount: monthly_savings,
        }}
      />
    </>

  );
}

interface MonthlySavingsSectionProps extends
  MonthlySavingsForRegimeAProps, MonthlySavingsForRegimeBProps {
  regime: Uppercase<RegimeLiteral>
}
export function MonthlySavingsSection({
  regime,
  monthly_savings,
  monthly_benefit,
  monthly_personal_payment,
  monthly_tax_deduction,
  total_monthly_benefit,
}: MonthlySavingsSectionProps) {
  return (
    <>
      {regime === 'A' && <MonthlySavingsForRegimeA monthly_benefit={monthly_benefit} monthly_savings={monthly_savings} total_monthly_benefit={total_monthly_benefit} />}
      {regime === 'B' && <MonthlySavingsForRegimeB monthly_personal_payment={monthly_personal_payment} monthly_savings={monthly_savings} monthly_tax_deduction={monthly_tax_deduction} />}
    </>
  );
}
