import { getIcon } from 'app/utils/staticStorage';
import React from 'react';

export function NavBar() {
  return (
    <header className="shadow-sm">
      <nav className="container w-100 py-3">
        <figure className="mb-0">
          <img src={getIcon('bice-logo.svg')} alt="Bice Lifeplan Logo" width={87} />
        </figure>
      </nav>
    </header>
  );
}
