import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getQueryParams } from 'app/utils/queryParams';
import { SIMULATION_NAMES } from 'app/utils/constants';
import { PolicyProjectionContent } from '../PolicyProjectionContainer/components/PolicyProjectionContent';
import { InsuredUniversityPayload, insuredUniversityService } from '../simulations/insuredUniversity/insuredUniversityService';

export function InsuredUniversityPolicyProjection() {
  const { is_empty, ...payloadParameters } = getQueryParams<InsuredUniversityPayload>();
  const { data: response, isLoading } = useQuery({
    queryKey: ['INSURED_UNIVERSITY_POLICY_PROJECTION'],
    queryFn: () => insuredUniversityService.simulate(payloadParameters),
  });

  const { policy_data_by_year } = response?.data ?? {};
  const { columns, rows } = policy_data_by_year ?? {};

  return (
    <PolicyProjectionContent
      isLoading={isLoading}
      title={SIMULATION_NAMES.INSURED_UNIVERSITY}
      columns={columns ?? []}
      rows={rows ?? []}
    />
  );
}
