/* eslint-disable react/no-unstable-nested-components */
import React, { CSSProperties } from 'react';

import {
  Block,
  BlockTitle,
  FieldDisplayer,
  OuterHeader,
  BaseAlert,
  LoadingSpinner,
} from 'app/components/common';
import { HorizontalStackBarChart } from 'app/components/charts';
import { StackedBarChartConfig, WithCurrencySwitch } from 'app/utils/types';

import { getIcon } from 'app/utils/staticStorage';
import { getMutualFundsWording } from 'app/constants/wording/mutualFunds';

import { getWording } from 'app/constants/wording/wording';
import { useTransformCurrency as r, withPrefix } from 'app/utils/hooks/useTransformCurrency';
import { CurrencySwitch } from 'app/components/common/CurrencySwitch';
import { InformativeDeclarationDisclaimer } from 'app/components/common/InformativeDeclarationDisclaimer';
import {
  MutualFundsSimulationVariants,
  MutualFundsSimulationVariantsResponse,
  SimulateVariants,
} from './mutualFundsService';
import {
  VariantResponseCounter,
} from './messagesGenerator';
import { useMutualFunds } from './useMutualFunds';

interface MutualFundsContentProps extends WithCurrencySwitch {
  data: MutualFundsSimulationVariantsResponse,
  parameters: MutualFundsSimulationVariants,
  chartData: StackedBarChartConfig,
  containerClassname?: string
  hideOuterHeader?: boolean
  style?: CSSProperties,
}

function MutualFundsContent({
  data,
  chartData,
  parameters,
  containerClassname,
  hideOuterHeader,
  style,
  showCurrencySwitch,
}: MutualFundsContentProps) {
  const { simulate, goal } = parameters;
  const { wording } = getWording();

  return (
    <section className="h-100 d-flex flex-column" style={style ?? { maxWidth: 650 }}>
      {!hideOuterHeader && <OuterHeader text={`Si ${wording.start} hoy a ahorrar: `} />}

      <div className={containerClassname ?? 'simulation-renderer simulation-renderer-shadow'}>
        {showCurrencySwitch && <CurrencySwitch />}

        <Block className="mb-4">
          <h3 className="text-dark fw-bold fs-small mb-3 mb-md-2">
            Para lograr
            {' '}
            {wording.your}
            {' '}
            meta de
            {' '}
            {r(goal, withPrefix)}
            {' '}
            <span className="text-secondary">
              {wording.willHave}
              {' '}
              que ahorrar
              {' '}
              {simulate === 'monthly_contribution' ? 'mensualmente' : 'durante'}
            </span>
            :
          </h3>

          <VariantResponseCounter data={data} simulate={simulate} />
        </Block>

        <Block className="mb-5">
          <h3 className="text-dark fw-bold fs-base">
            Así se podría ver
            {' '}
            {wording.your}
            {' '}
            total ahorrado en Fondos Mutuos:
          </h3>

          <HorizontalStackBarChart chartData={chartData} />
        </Block>

        <Block className="mb-3">
          <InformativeDeclarationDisclaimer />
        </Block>
      </div>
    </section>
  );
}
interface EmptyContentProps {
  simulate: SimulateVariants
}

function EmptyContent({ simulate }: EmptyContentProps) {
  const { wording } = getMutualFundsWording();

  return (
    <section className="h-100 d-flex flex-column" style={{ maxWidth: 575 }}>
      <OuterHeader text={wording.outerHeader} />

      <div className="simulation-renderer simulation-renderer-shadow">
        <Block className="mb-4">
          <BlockTitle
            overwrittenClassName="small text-left fw-bold"
            primaryText={{ content: wording.targetGoal.primaryText(0) }}
            secondaryText={{ content: wording.targetGoal.secondaryText[simulate] }}
          />

          <FieldDisplayer
            extraClassName="fs-1 bg-light-secondary"
            primaryText={{ content: wording.emptyContent.fieldDisplayer.primaryText[simulate](0) }}
            secondaryText={{
              content:
                wording.emptyContent.fieldDisplayer.secondaryText[simulate],
            }}
          />
        </Block>

        <Block>
          <BlockTitle
            extraClassName="fs-6"
            primaryText={{ content: wording.chartHeader }}
          />
        </Block>

        <div className="d-flex align-items-center justify-content-center" style={{ paddingBlock: '5.25rem' }}>
          <figure className="d-flex flex-column  align-items-center justify-content-center">
            <img src={getIcon('rocket.svg')} alt="Emtpy simulation state" className="mb-2" />

            <figcaption className="text-primary text-wrap text-center h6 fw-bold lh-base" style={{ width: 200 }}>
              {wording.emptyContent.chartPlaceholder}
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
}

interface MutualFundsProps extends WithCurrencySwitch {
  containerClassname?: string
  containerStyle?: CSSProperties
  hideOuterHeader?: boolean
}

export function MutualFunds({
  containerClassname,
  containerStyle,
  hideOuterHeader,
  showCurrencySwitch,
}: MutualFundsProps) {
  const {
    chartData,
    simulationResult,
    payloadParameters,
    showEmptyContent,
    isError,
    isLoading,
  } = useMutualFunds();

  if (showEmptyContent) {
    return <EmptyContent simulate={payloadParameters.simulate} />;
  }

  if (simulationResult && chartData) {
    return (
      <MutualFundsContent
        showCurrencySwitch={showCurrencySwitch}
        chartData={chartData}
        data={simulationResult}
        parameters={payloadParameters}
        containerClassname={containerClassname}
        style={containerStyle}
        hideOuterHeader={hideOuterHeader}
      />
    );
  }
  if (isLoading) return <LoadingSpinner color="primary" size={256} borderWidth={4} />;
  if (isError) return <BaseAlert type="alert-danger" text="Oh no! Algo ha ido mal. Intente más tarde..." />;

  return null;
}
