/*
  Sometimes we need to access to theme colors via javascript.
  This objects should map bootstrap theme color names to its value in order to be accesible from
  components
*/
export const themeColors = {
  primary: '#0C4497',
  secondary: '#00B0B9',
  info: '#1C7CD5',
  pink: '#E17DAC',
  purple: '#796EE8',
  orange: '#EE9800',
};
