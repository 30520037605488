import { getIcon } from 'app/utils/staticStorage';
import React from 'react';
import { CompensationPlan, RegimeLiteral } from 'app/utils/types';
import { formatUF } from 'app/utils/formatters';
import { BaseAlert } from './BaseAlert';

// Aliases for values in order to improve `renderDisclaimerMap` readability
const REGIME_A = 'A';
const REGIME_B = 'B';
const COMPENSATION_PLAN_A = 'A';
const COMPENSATION_PLAN_B = 'B';

function RecoverCapitalByFiscalBonusesDisclaimer() {
  return (
    <>
      <span>Recuerda que: En caso de </span>
      <span className="fw-bold">Muerte del asegurado, </span>
      <span>
        <span>
          {'los beneficiarios de pensión recibirán una indemnización igual a su '}
        </span>
        <span className="fw-bold">capital asegurado, </span>
        <span>
          el monto ahorrado que podrán optar por traspasar los ahorros a la AFP o retirarlos,
          devolviendo la bonificación fiscal.
        </span>
      </span>
    </>
  );
}

function RecoverInsuranceAmountByFiscalBonusesDisclaimer() {
  return (
    <>
      <span>Recuerda que: En caso de </span>
      <span className="fw-bold">Muerte del asegurado, </span>
      <span>
        <span>
          los beneficiarios de pensión recibirán la indemnización del seguro y podrán
          optar por traspasar los ahorros a la AFP o retirarlos,
          devolviendo la bonificación fiscal.
        </span>
      </span>
    </>
  );
}

interface RecoverCapitalByTaxesDisclaimerProps {
  insuredCapital: number
}

function RecoverCapitalByTaxesDisclaimer({ insuredCapital }: RecoverCapitalByTaxesDisclaimerProps) {
  return (
    <>
      <span>Recuerda que: En caso de </span>
      <span className="fw-bold">Muerte del asegurado, </span>
      <span>
        <span>{`beneficiarios de pensión recibirán una indemnización de ${formatUF(insuredCapital, { withPrefix: true })} `}</span>
        <span>
          y podrán optar por traspasar los ahorros a la AFP o retirarlos
          pagando un 15% de impuesto.
        </span>
      </span>
    </>
  );
}

const renderDisclaimerMapContent: Record<Uppercase<RegimeLiteral>,
Record<CompensationPlan, (insuredCapital: number) => JSX.Element>> = {
  [REGIME_A]: {
    [COMPENSATION_PLAN_A]: () => <RecoverInsuranceAmountByFiscalBonusesDisclaimer />,
    [COMPENSATION_PLAN_B]: () => <RecoverCapitalByFiscalBonusesDisclaimer />,
  },
  [REGIME_B]: {
    [COMPENSATION_PLAN_A]: () => <RecoverCapitalByFiscalBonusesDisclaimer />,
    [COMPENSATION_PLAN_B]: (insuredCapital: number) => (
      <RecoverCapitalByTaxesDisclaimer
        insuredCapital={insuredCapital}
      />
    ),
  },
};

interface DisclaimerByRegimeAndPlanProps {
  regime: Uppercase<RegimeLiteral>
  plan: CompensationPlan
  insuredCapital: number
}

export function DisclaimerByRegimeAndPlan({
  plan,
  regime,
  insuredCapital,
}: DisclaimerByRegimeAndPlanProps) {
  return (
    <BaseAlert
      dismissible
      alertClassName="text-body small border-1 border-info"
      icon={{
        src: getIcon('info.svg'),
        alt: '',
      }}
      type="alert-info"
      renderContent={() => (
        <p className="m-0 text-primary fs-smaller">
          {renderDisclaimerMapContent[regime][plan](insuredCapital)}
        </p>
      )}
    />

  );
}
