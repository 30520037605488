import React, { Fragment } from 'react';
import { getIcon } from 'app/utils/staticStorage';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import clsx from 'clsx';
import { Card } from './Card';
import { FieldDisplayer } from './FieldDisplayer';

interface SummationBoxProps {
  id: string
  renderText: () => JSX.Element
  amount: number
}

function SummationBox({ renderText, amount, id }: SummationBoxProps) {
  const { currencyPrefix } = useCurrencyContext();

  return (
    <div className="d-flex flex-column gap-2">
      <p className="mb-0 fs-smaller">
        {renderText()}
      </p>
      <FieldDisplayer
        overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
        primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
        secondaryText={{ content: r(amount), color: 'text-dark' }}
      />
    </div>
  );
}

interface SummationBoxesProps {
  boxes: SummationBoxProps[]
  totalBox: SummationBoxProps
  reversed?: boolean
}

export function SummationBoxes({ boxes, totalBox, reversed }: SummationBoxesProps) {
  return (

    <div className={clsx('big-simulation-result-cards', reversed && 'flex-row-reverse')}>
      <div className="summation-boxes-wrapper">
        {reversed && <div data-arrow-direction="left" className="arrow" />}
        <Card>
          <div className="summation-boxes">
            {boxes.map(({ amount, id, renderText }, index) => (
              <Fragment key={id}>
                <SummationBox
                  id={id}
                  amount={amount}
                  renderText={renderText}
                />

                {index !== boxes.length - 1 && (
                  <figure className="mb-0">
                    <img src={getIcon('add.svg')} width={24} height={24} alt="plus icon" />
                  </figure>
                )}
              </Fragment>
            ))}
          </div>
        </Card>

        {!reversed && <div data-arrow-direction="right" className="arrow" />}
      </div>

      <div className="total-box">
        <Card>
          <SummationBox
            id={totalBox.id}
            amount={totalBox.amount}
            renderText={totalBox.renderText}
          />
        </Card>
      </div>
    </div>
  );
}
