export function getQueryParams<T>() {
  const rawSearchParams = new URLSearchParams(window.location.search);
  const parsedQueryParams = {} as T;

  rawSearchParams.forEach((value, key) => {
    if (value === 'null') {
      return;
    }

    parsedQueryParams[key as keyof T] = value as unknown as T[keyof T];
  });

  return parsedQueryParams;
}

export function createUrlWithQueryParams(
  url: string,
  queryParams: Record<string, string>
) {
  const rawQueryParams = new URLSearchParams(queryParams);

  return `${url}?${rawQueryParams.toString()}`;
}
