import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getQueryParams } from 'app/utils/queryParams';
import { SIMULATION_NAMES } from 'app/utils/constants';
import { PolicyProjectionContent } from '../PolicyProjectionContainer/components/PolicyProjectionContent';
import { FullFlexiblePayload, fullFlexibleService } from '../simulations/fullFlexible/fullFlexibleService';

export function FullFlexiblePolicyProjection() {
  const { is_empty, ...payloadParameters } = getQueryParams<FullFlexiblePayload>();
  const { data: response, isLoading } = useQuery({
    queryKey: ['FULL_FLEXIBLE_POLICY_PROJECTION'],
    queryFn: () => fullFlexibleService.simulate(payloadParameters),
  });

  const { policy_data_by_year } = response?.data ?? {};
  const { columns, rows } = policy_data_by_year ?? {};

  return (
    <PolicyProjectionContent
      isLoading={isLoading}
      title={SIMULATION_NAMES.FULL_FLEXIBLE}
      columns={columns ?? []}
      rows={rows ?? []}
    />
  );
}
