import React from 'react';

import { getEnv } from 'app/utils/publicEnv';

export function CommonDisclaimers() {
  return (
    <>
      <p>
        El resultado obtenido es meramente referencial y puede variar.
        No constituye un estudio o análisis previsional.
        El resultado proyectado se ha obtenido en base a la información proporcionada por usted
        y teniendo presente los siguientes supuestos.
        Infórmese aquí sobre los supuestos para esta estimación. Más información.
      </p>

      <p>
        BICE LifePplan es una iniciativa de BICE Chileconsult Servicios Financieros Limitada,
        propietaria del sitio web y su contenido.
        Se pretende dar a conocer, de manera genérica y en un solo sitio digital,
        distintos productos y servicios financieros, de ahorro y previsionales que ofrecen algunas
        de las entidades que forman parte de BICECORP, como lo son:
        BICE Vida Compañía de Seguros S.A. y BICE Inversiones. Este sitio es
        meramente informativo y no tiene por finalidad la promoción, publicidad, contratación
        o comercialización de productos y/o servicios.
      </p>

      <p>
        Este sitio no describe detalladamente todas las características, condiciones y términos
        de los productos financieros, de ahorro y/o previsionales, ya que quello es privativo
        de la entidad que lo comercializa.
      </p>

      <p className="mb-0">
        La eventual contratación de los productos y/o servicios que se mencionan en este sitio
        se deberá materializar en las plataformas de cada entidad BICE o presencialmente,
        debiendo existir, por parte de cada entidad, la adecuada y completa asesoría previa y
        específica acorde a las necesidades de cada cliente.
      </p>
    </>
  );
}

export function PrivacyDisclaimer() {
  return (
    <p className="mb-0">
      Todos los datos que ingreses en este simulador son confidenciales.
      Por otro lado, los resultados de esta herramienta son solo una simulación,
      y dependen de supuestos que pueden cambiar durante el tiempo. Por lo tanto, los resultados de
      este simulador son estimativos y no constituyen una garantía o una oferta por parte de
      BICE Vida Compañía de Seguros S.A.
    </p>
  );
}

interface FooterProps {
  children: JSX.Element
}

export function Footer({ children }: FooterProps) {
  return (
    <footer style={{ backgroundColor: '#f4f4f4' }} className="p-4">
      <div className="fs-small text-center mb-3">
        <span>{'Enviado por '}</span>
        <a className="text-secondary text-decoration-underline" href={getEnv('LIFEPLAN_HOME_URL') ?? '/'}>
          BICE LifePlan
        </a>
      </div>

      <div className="fs-smaller text-center">
        {children}
      </div>
    </footer>
  );
}
