import React from 'react';
import { getIcon } from 'app/utils/staticStorage';
import { currencyFormatter } from 'app/utils/formatters';
import { getQueryParams } from 'app/utils/queryParams';
import { InfographContainerQueryParams } from 'app/utils/types';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { BenefitCardsSection, GreetingsHeader, HelpCardsSection } from './components';
import { InsuredUniversity } from '../simulations/insuredUniversity';
import { HelpCardProps } from './components/HelpCardsSection';
import { Footer, PrivacyDisclaimer } from './components/Footer';
import { InsuredUniversityPayload } from '../simulations/insuredUniversity/insuredUniversityService';

const helpCards: HelpCardProps[] = [
  {
    title: '¿Qué es LifePLan?',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>
          {'Nace con el objetivo de que encuentres, en las distintas etapas de tu vida '}
        </span>
        <span className="fw-bold">
          alternativas de ahorro y protección para disfrutar tu futuro con la
          tranquilidad y seguridad.
        </span>
      </p>
    ),
  },
  {
    title: '¿Qué es el seguro Universidad asegurada?',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>{'Es un '}</span>
        <span className="fw-bold">
          {'seguro '}
        </span>
        <span>{'que además de proteger a los que más quieres, '}</span>
        <span className="fw-bold">
          te permite asegurar el capital que necesitas para concretar
          tus metas de mediano y largo plazo.
        </span>
      </p>
    ),
  },
];

const benefitCards = () => {
  const { death_insured_capital } = getQueryParams<InsuredUniversityPayload>();
  const { ufRatio } = useCurrencyContext();

  const insuredCapital = death_insured_capital * ufRatio;

  return [
    {
      id: 'diversity',
      icon: {
        src: getIcon('stats.svg'),
        alt: 'Stats icon',
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span>{'Cuentas con distintos '}</span>
          <span className="fw-bold">fondos de inversión a tu medida.</span>
        </p>
      ),
    },
    {
      id: 'payment',
      icon: {
        src: getIcon('money.svg'),
        alt: 'money icon',
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span className="fw-bold">{'Puedes recibir el pago del capital asegurado '}</span>
          <span>en un pago único o en rentas anuales.</span>
        </p>
      ),
    },
    {
      id: 'insurance',
      icon: {
        src: getIcon('hand-taking-heart.svg'),
        alt: 'hand taking hearth',
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span className="fw-bold">{'Estarás asegurado '}</span>
          <span>{'con un capital de '}</span>
          <span className="fw-bold">{`${currencyFormatter.format(insuredCapital)} `}</span>
          <span>para tu beneficiario.</span>
        </p>
      ),
    },
  ];
};

export function InsuredUniversityInfograph() {
  const { customer_name } = getQueryParams<InfographContainerQueryParams>();

  return (
    <div className="h-100">
      <div className="px-3">
        <GreetingsHeader customerName={customer_name} />
      </div>

      <div className="d-flex justify-content-center">
        <InsuredUniversity containerClassname="mb-3 px-3" />
      </div>

      <div>
        <HelpCardsSection title="Te ayudamos a entender" cards={helpCards} />
      </div>

      <div className="p-3">
        <BenefitCardsSection
          renderTitle={() => (
            <h3 className="fs-6 text-primary fw-bold text-center">
              <span>{'¿Cuáles son los beneficios de contratar '}</span>
              <span className="text-secondary">Universidad asegurada</span>
              <span>?</span>
            </h3>
          )}
          cards={benefitCards()}
        />
      </div>

      <Footer>
        <PrivacyDisclaimer />
      </Footer>
    </div>
  );
}
