import React from 'react';

interface OuterHeaderProps {
  text?: string
  renderContent?: JSX.Element
}

export function OuterHeader({ text, renderContent }: OuterHeaderProps) {
  return (
    <header className="mb-2">
      <h2 className="h5 text-primary fw-bold">
        {renderContent || text}
      </h2>
    </header>

  );
}
