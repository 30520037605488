import { api } from 'app/utils/api';
import { SIMULATION_ENDPOINTS } from 'app/utils/endpoints';
import { Genders, RegimeLiteral, ResultingRegime } from 'app/utils/types';

export const RegimeLiteralMap: Record<RegimeLiteral, ResultingRegime> = {
  a: {
    regimeLiteral: 'a',
    regimeProperty: 'regime_a',
  },
  b: {
    regimeLiteral: 'b',
    regimeProperty: 'regime_b',
  },
};
export interface ApvSimulationPayload {
  age: number,
  gender: Genders,
  monthly_savings: number
  net_income: number,
  product: 'APV'
  regime?: RegimeLiteral
}

export interface RegimeData {
  current_year_benefit: number
  current_year_net_income: number
  current_year_tax: number
  monthly_benefit: number
  net_income: number
  tax: number
  tax_base: number
  tax_discount: number
  tax_rate: number
  yearly_benefit: number
}

export interface ApvSimulationResponse {
  regime_a: RegimeData
  regime_b: RegimeData
  without_apv: RegimeData

  current_year_legal_discounts: number
  current_year_savings: number
  estimated_gross_wage: number
  estimated_legal_discounts: number
  monthly_savings: number
  uf: number
  utm: number
}

export interface ApvSimulationData extends ApvSimulationResponse {
  regime: ResultingRegime
  total_savings: number
  pension_rise: number
}

export interface ProjectedSavingsPayload {
  gender: string
  date_of_birth: string
  monthly_savings: number
  net_salary: number
  apv_regime: string,
  risk_profile: string
}
export interface ProjectedSavingsResponse {
  total_fiscal_contribution: number
  total_personal_contribution: number
  pension_rise: number
  fiscal_contribution_without_interest: number,
  personal_savings_without_interest: number
  savings_expected_return: number
}

export type ApvProjectedSavingsPayload = ApvSimulationPayload & ProjectedSavingsPayload & {
  is_empty?: boolean
};

export const apvService = {
  simulate: async (payload: ApvSimulationPayload) => api
    .post<ApvSimulationResponse>(SIMULATION_ENDPOINTS.APV, payload),
  simulateProjectedSavings: async (payload: ProjectedSavingsPayload) => api
    .post<ProjectedSavingsResponse>(SIMULATION_ENDPOINTS.SAVINGS_PROJECTION, payload),
};
