import { CurrencyOption } from './types';

export const transformToUF = (clp: number, ufRatio: number) => clp / ufRatio;

export const transformToCLP = (uf: number, ufRatio: number) => uf * ufRatio;

export const transformCurrencyMap: Record<CurrencyOption,
(value: number, ratio: number) => number> = {
  clp: transformToCLP,
  clf: transformToUF,
};
