import clsx from 'clsx';
import React from 'react';
import { TextContainer } from 'app/utils/types';

interface FieldDisplayerProps extends TextContainer {
  renderContent?: JSX.Element | JSX.Element[]
}

export function FieldDisplayer({
  overwrittenClassName,
  extraClassName,
  primaryText,
  secondaryText,
  renderContent,
}: FieldDisplayerProps) {
  return (
    <div className={clsx(
      overwrittenClassName ?? 'fw-bold px-2_5 pe-md-5 py-2 d-inline-block rounded-4',
      extraClassName
    )}
    >
      {
        renderContent
          ?? (
          <>
            <span className={clsx('me-2', primaryText?.color ?? 'text-primary')}>
              {primaryText?.content}
            </span>
            <span className={clsx(secondaryText?.color ?? 'text-secondary')}>
              {secondaryText?.content}
            </span>
          </>
          )
      }

    </div>

  );
}
