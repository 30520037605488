import React from 'react';
import {
  Footer, LoadingSpinner, NavBar, Table,
} from 'app/components/common';
import { RawColumn, RowShape, useBuildTable } from 'app/utils/hooks/useBuildTable';
import { PolicyProjectionContainerHeader } from './PolicyProjectionContainerHeader';
import { PolicyHelpSection } from './PolicyHelpSection';

interface PolicyProjectionContentProps {
  title: string
  columns: RawColumn[]
  rows: RowShape[]
  isLoading: boolean
}

export function PolicyProjectionContent({
  columns, rows, title, isLoading,
}: PolicyProjectionContentProps) {
  const { table } = useBuildTable({ columns, rows });

  if (isLoading) return <LoadingSpinner color="primary" size={256} borderWidth={4} />;

  return (
    <div>
      <NavBar />

      <div id="policy-projection-content-body" className="mx-5 px-5 my-4">
        <section className="mb-4">
          <PolicyProjectionContainerHeader title={title} />
          <Table table={table} tableClassName="policy-table" />
        </section>

        <PolicyHelpSection title={title} />
      </div>

      <Footer />
    </div>

  );
}
