import {
  AccessorFn, createColumnHelper, getCoreRowModel, useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { decimalFormatter } from '../formatters';

export interface RawColumn {
  id: string
  label: string
  order: number
}

export interface RowShape {
  accumulated_prime: number
  age: number
  coverage_costs: number
  death_compensation: number
  policy_expenses: number
  policy_value: number
  year: number
}

export interface RawTableResponse {
  columns: RawColumn[],
  rows: RowShape[],
  costs: number[],
  yearly_contributions: number[]
}

export interface WithPolicyByYearData {
  policy_data_by_year: RawTableResponse
}

interface UseBuildTableOptions {
  columns: RawColumn[]
  rows: RowShape[]
}

export function useBuildTable({ columns, rows }: UseBuildTableOptions) {
  const columnHelper = createColumnHelper<RowShape>();

  const builtColumns = useMemo(() => (columns ?? [])
    .sort((first, next) => first.order - next.order)
    .map(({ id, label }) => columnHelper.accessor(id as unknown as AccessorFn<RowShape>,
      {
        header: label,
        // TODO: ideally, backend should number format as metadata in order to know how type of
        // number will be displayed (currency, float, integer, etc...)

        // For now, all numbers will be rounded and formatted directly by backend
        //  to avoid float overflow

        // WARNING: directly number assertion should break table values if string value is given
        // Currently we know it will always return numbers, but this could change in the future.
        cell: (cell) => decimalFormatter.format(cell.getValue() as number),
      })), [columns, rows]);

  const table = useReactTable({
    columns: builtColumns ?? [],
    data: rows ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return { table };
}
