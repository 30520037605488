// Strings formatters for special cases

import { CurrencyOption } from './types';

export const currencyFormatter = new Intl.NumberFormat('es-CL', {
  currency: 'USD',
  style: 'currency',
  maximumFractionDigits: 0,
  currencyDisplay: 'narrowSymbol',
});

// It is used when we want to format numbers without automatically add currency symbol.
// Its most common use is when the target text shows the currency symbol in some color and
// the amount is different color
export const numberFormatter = new Intl.NumberFormat('es-CL', {
  maximumFractionDigits: 0,
});

export const decimalFormatter = new Intl.NumberFormat('es-CL', {
  maximumFractionDigits: 2,
});

export const stringFormatter = {
  capitalize: (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1)}`,
};

export interface FormatCurrencyOptions {
  withPrefix?: boolean
  withSuffix?: boolean
}

export const formatUF = (
  amount: number,
  formatOptions: FormatCurrencyOptions = { withPrefix: false, withSuffix: false }
) => {
  const { withPrefix, withSuffix } = formatOptions;

  if (withPrefix) {
    return `UF ${decimalFormatter.format(amount)}`;
  } if (withSuffix) {
    return `${decimalFormatter.format(amount)} UF`;
  }
  return decimalFormatter.format(amount);
};

export const formatCLP = (
  amount: number,
  formatOptions: FormatCurrencyOptions = { withPrefix: false }
) => {
  const { withPrefix } = formatOptions;

  return withPrefix
    ? currencyFormatter.format(amount)
    : numberFormatter.format(amount);
};

export const formatCurrencyMap:
Record<CurrencyOption, (amount: number, formatOptions?: FormatCurrencyOptions) => string> = {
  clp: formatCLP,
  clf: formatUF,
};

type FormatPercentageOpts = Partial<{
  withTransform: boolean
  withFractionDigits: boolean
}>;

export const formatPercentage = (amount: number, opts?: FormatPercentageOpts) => {
  const FRACTION_DIGITS = 2;
  const { withTransform, withFractionDigits } = opts ?? {};
  const value = withTransform ? amount / 100 : amount;

  const percentageFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: withFractionDigits ? FRACTION_DIGITS : 0,
    maximumFractionDigits: withFractionDigits ? FRACTION_DIGITS : 0,
  });

  return percentageFormatter.format(value);
};
