import { Genders, WithCurrencyInfo, WithEmptyOption } from 'app/utils/types';
import { api } from 'app/utils/api';
import { SIMULATION_ENDPOINTS } from 'app/utils/endpoints';
import { RiskProfileVariants } from '../mutualFunds/mutualFundsService';

export type InsurancePlan = 'safe_plan' | 'safer_plan' | 'mega_safe_plan';

export interface InsuranceWithSavingsPayload extends WithEmptyOption {
  gender: Genders
  date_of_birth: string,
  insurance_plan: InsurancePlan,
  net_income: number
  additional_savings: number
  risk_profile: RiskProfileVariants
}

export interface InsuranceWithSavingsRegime {
  regime: 'A' | 'B'
  current_year_savings: number
  current_year_benefit: number
  monthly_pension_improvement: number
  total_apv_savings: number
  savings_expected_return: number
  total_additional_savings: number,
  fiscal_contribution_without_interest: number,
  contributions_from_plan_without_interest: number
}

type Regimes = 'A' | 'B';

export interface InsuranceWithSavingsResponse extends WithCurrencyInfo {
  A: InsuranceWithSavingsRegime
  B: InsuranceWithSavingsRegime
  recommended_regime: Regimes
}

export const insuranceWithSavingsService = {
  simulate: async (payload: InsuranceWithSavingsPayload) => api
    .post<InsuranceWithSavingsResponse>(SIMULATION_ENDPOINTS.INSURANCE_WITH_SAVINGS, payload),
};
