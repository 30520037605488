import React from 'react';

import { StackedBarChartConfig } from 'app/utils/types';
import { BarChart, ResponsiveContainer, YAxis } from 'recharts';
import { StackBar } from './StackBar';

interface VerticalStackBarChartProps {
  chartData: StackedBarChartConfig
  height?: number
}

export function VerticalStackBarChart({ chartData, height }: VerticalStackBarChartProps) {
  const DEFAULT_HEIGHT = 420;

  return (
    <div className="d-flex">
      <ResponsiveContainer width={90} height={height ?? DEFAULT_HEIGHT}>
        <BarChart data={[chartData]}>

          {/* `domain` property needed for always take full height with chart */}
          <YAxis domain={[0, 'dataMax']} type="number" hide />
          {
            chartData.stacks.map((stack, index) => StackBar({
              stackId: chartData.name,
              isFirst: index === 0,
              isLast: index === chartData.stacks.length - 1,
              stack,
              withInternalLabel: false,
            }))
          }
        </BarChart>
      </ResponsiveContainer>

      <div className="ms-4 mt-2">
        {/* `reverse` needed in order to render up to down and sync with chart stack bar order */}
        {chartData.stacks.reverse().map((stack) => {
          if (!stack.value) return null;

          return (
            <div
              key={stack.field}
              className="ms-2 mb-3"
            >
              {stack.label(stack.color)}
            </div>
          );
        }
        )}
      </div>
    </div>
  );
}
