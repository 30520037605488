import React from 'react';
import { getIcon } from 'app/utils/staticStorage';
import { currencyFormatter } from 'app/utils/formatters';
import { getQueryParams } from 'app/utils/queryParams';
import { InfographContainerQueryParams } from 'app/utils/types';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { BenefitCardsSection, GreetingsHeader, HelpCardsSection } from './components';
import { FullFlexible } from '../simulations/fullFlexible';
import { HelpCardProps } from './components/HelpCardsSection';
import { Footer, PrivacyDisclaimer } from './components/Footer';
import { FullFlexiblePayload } from '../simulations/fullFlexible/fullFlexibleService';

const helpCards: HelpCardProps[] = [
  {
    title: '¿Qué es LifePlan',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>
          {'Nace con el objetivo de que encuentres, en las distintas etapas de tu vida '}
        </span>
        <span className="fw-bold">
          alternativas de ahorro y protección para disfrutar tu futuro con la
          tranquilidad y seguridad.
        </span>
      </p>
    ),
  },
  {
    title: '¿Qué es seguro Full Flexible?',
    renderDescription: () => (
      <p className="mb-0 fs-smaller">
        <span>{'Es un '}</span>
        <span className="fw-bold">{'seguro '}</span>
        <span>{'que además de proteger a los que más quieres, '}</span>
        <span className="fw-bold">te permite asegurar el capital que necesitas para concretar tus metas a mediano y largo plazo.</span>
      </p>
    ),
  },
];

const benefitCards = () => {
  const { death_insured_capital } = getQueryParams<FullFlexiblePayload>();
  const { ufRatio } = useCurrencyContext();

  const insuredCapital = death_insured_capital * ufRatio;

  return [
    {
      id: 'selectable',
      icon: {
        alt: 'shield icon',
        src: getIcon('shield.svg'),
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span className="fw-bold">{'Puedes elegir '}</span>
          <span>
            entre dos planes de seguro, en los que podrás optar por
            privilegiar el ahorro o la protección.
          </span>
        </p>
      ),
    },
    {
      id: 'contributory',
      icon: {
        alt: 'money icon',
        src: getIcon('money.svg'),
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span>{'Podrás realizar '}</span>
          <span className="fw-bold">{'aportes extras y retiros '}</span>
          <span>en caso de imprevistos durante la vigencia de la póliza.</span>
        </p>
      ),
    },
    {
      id: 'insured',
      icon: {
        alt: 'hand taking heart icon',
        src: getIcon('hand-taking-heart.svg'),
      },
      renderContent: () => (
        <p className="text-primary small text-center">
          <span className="fw-bold">{'Estarás asegurado '}</span>
          <span>{'con un capital de '}</span>
          <span className="fw-bold">{`${currencyFormatter.format(insuredCapital)} `}</span>
          <span>para tus beneficiarios.</span>
        </p>
      ),
    },
  ];
};

export function FullFlexibleInfograph() {
  const { customer_name } = getQueryParams<InfographContainerQueryParams>();

  return (
    <div className="h-100">
      <div className="px-3">
        <GreetingsHeader customerName={customer_name} />
      </div>

      <div className="d-flex justify-content-center">
        <FullFlexible containerClassname="px-3 mb-3" />
      </div>

      <div>
        <HelpCardsSection title="Te ayudamos a entender:" cards={helpCards} />
      </div>

      <div className="p-3">
        <BenefitCardsSection
          renderTitle={() => (
            <h3 className="fs-6 text-primary fw-bold text-center">
              <span>{'¿Cuáles son los beneficios de contratar un '}</span>
              <span className="text-secondary">Seguro Full Flexible</span>
              <span>?</span>
            </h3>
          )}
          cards={benefitCards()}
        />
      </div>

      <Footer>
        <PrivacyDisclaimer />
      </Footer>
    </div>
  );
}
