import { getQueryParams } from 'app/utils/queryParams';
import { WithWording, Wording } from 'app/utils/types';

const CLIENT_WORDING = {
  your: 'tu',
  yours: 'tus',
  wouldBe: 'te',
  suggestion: 'te',
  start: 'comienzas',
  willHave: 'tendrás',
  retirement: 'jubiles',
  willSave: 'habrás',
  willWin: 'ganarás',
  willSaveInPlan: 'ahorrarás',
};

type ContentStructure = typeof CLIENT_WORDING;

const EXECUTIVE_WORDING = {
  your: 'su',
  yours: 'sus',
  wouldBe: 'se',
  suggestion: 'le',
  start: 'comienza',
  willHave: 'tendrá',
  retirement: 'jubile',
  willSave: 'habrá',
  willWin: 'ganará',
  willSaveInPlan: 'ahorrará',

};

const WORDING_MAP: Record<Wording, ContentStructure> = {
  CLIENT: CLIENT_WORDING,
  EXECUTIVE: EXECUTIVE_WORDING,
};

export const getInsuranceWithSavingsWording = () => {
  const DEFAULT_WORDING = WORDING_MAP.CLIENT;
  const { wording: choosenWording } = getQueryParams<WithWording>();

  if (!choosenWording) {
    return { choosenWording: 'CLIENT', wording: DEFAULT_WORDING };
  }

  return { choosenWording, wording: WORDING_MAP[choosenWording] };
};
