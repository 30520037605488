import React from 'react';

export interface DisclaimersListProps {
  items: string[]
}

export function DisclaimersList({ items }: DisclaimersListProps) {
  const formatDisclaimerItem = (text: string) => `* ${text}`;
  const generateDisclaimerKey = (index: number) => `disclaimer-${index}`;

  return (
    <ul className="list-unstyled">
      {items.map((text, index) => (
        <li className="text-primary fs-smaller" key={generateDisclaimerKey(index)}>{formatDisclaimerItem(text)}</li>
      ))}
    </ul>

  );
}
