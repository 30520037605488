import React from 'react';

interface PolicyProjectionContainerHeaderProps {
  title: string
}

export function PolicyProjectionContainerHeader({ title }: PolicyProjectionContainerHeaderProps) {
  return (
    <header className="mb-4">
      <h2 className="text-center fw-bold fs-6">
        <span className="text-primary">{'Proyección Plan de Ahorro (Valores expresados en UF): '}</span>
        <span className="text-secondary">{title}</span>
      </h2>
    </header>
  );
}
