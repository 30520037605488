import React, { useState } from 'react';
import clsx from 'clsx';
import { IconProps } from 'app/utils/types';
import { getIcon } from 'app/utils/staticStorage';

interface BaseAlertProps {
  type: string,
  alertClassName?: string
  text?: string
  icon?: IconProps
  renderContent?: () => JSX.Element
  dismissible?: boolean
}

export function BaseAlert({
  type, text, icon, renderContent, dismissible, alertClassName,
}: BaseAlertProps) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  if (!show) {
    return null;
  }

  return (
    <div className={clsx('alert', type, alertClassName ?? 'alert py-3 px-4 show')} role="alert">
      <div className="d-flex gap-3 align-items-center">
        {icon && (
          <figure className="mb-0">
            <img src={icon.src} alt={icon.alt} width={32} height={32} />
          </figure>
        )}

        {text && <strong className="d-block fs-4 text-center font-weight-bold">{text}</strong>}
        {renderContent && renderContent()}

        {dismissible && (
          <button onClick={handleClose} type="button" className="btn p-0 align-self-start" aria-label="Cerrar">
            <img src={getIcon('close.svg')} alt="" width={24} height={24} />
          </button>
        )}
      </div>

    </div>
  );
}
