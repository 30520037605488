import { getEnv } from 'app/utils/publicEnv';

export const getIcon = (filename: string) => {
  const storageUrl = getEnv('BASE_STORAGE_URL') ?? '';

  return `${storageUrl}img/icons/${filename}`;
};

export const getIllustration = (filename: string) => {
  const storageUrl = getEnv('BASE_STORAGE_URL') ?? '';

  return `${storageUrl}img/illustrations/${filename}`;
};
