/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export interface HelpCardProps {
  title?: string
  description?: string
  renderTitle?: () => JSX.Element
  renderDescription?: () => JSX.Element
}

function HelpCard({
  title, description, renderDescription, renderTitle,
}: HelpCardProps) {
  return (
    <article className="bg-white rounded-4 p-3 shadow h-100">
      <header className="mb-3">
        {renderTitle?.()}
        {title && <h4 className="small text-primary fw-bold">{title}</h4>}
      </header>

      <div>
        {renderDescription?.()}
        {description && <p className="mb-0 small">{description}</p>}
      </div>
    </article>
  );
}

interface HelpCardsSectionProps {
  title: string
  cards: HelpCardProps[]
}

export function HelpCardsSection({ cards, title }: HelpCardsSectionProps) {
  return (
    <section className="bg-light-secondary py-3">
      <header>
        <h3 className="fs-6 fw-bold text-primary text-center mb-3">{title}</h3>
      </header>

      <div className="row justify-content-center g-3">
        {cards.map((card) => (
          <div key={card.title} className="col-4">
            <HelpCard {...card} />
          </div>
        ))}
      </div>
    </section>
  );
}
