import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import { transformToUF } from 'app/utils/money';
import React from 'react';
import { REGIME_B_MAX_MONTHLY_SAVINGS_UF } from 'app/utils/constants';
import { getIcon } from 'app/utils/staticStorage';
import { FieldDisplayerCard } from './FieldDisplayerCard';
import { FieldDisplayer } from './FieldDisplayer';
import { Popover } from './Popover';

interface MonthlySavingsDisplayerCardProps {
  apv_regime: 'A' | 'B';
  monthly_savings: number;
}

export function MonthlySavingsDisplayerCard({
  apv_regime,
  monthly_savings,
}: MonthlySavingsDisplayerCardProps) {
  const { currencyPrefix, ufRatio } = useCurrencyContext();
  const monthlySavingsExceedsRegimeMaximum = apv_regime === 'B' && transformToUF(monthly_savings, ufRatio) > REGIME_B_MAX_MONTHLY_SAVINGS_UF;

  const fieldDisplayer = (
    <FieldDisplayerCard
      iconName="circle-dollar.svg"
      renderText={() => (
        <>
          <span>Con un </span>
          <span className="text-secondary">ahorro mensual </span>
          <span>en APV de:</span>
        </>
      )}
      renderFieldDisplayer={() => (
        <div className="pb-3 pe-3">
          <FieldDisplayer
            overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
            primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
            secondaryText={{ content: r(monthly_savings), color: 'text-dark' }}
          />
        </div>
      )}
    />
  );

  if (monthlySavingsExceedsRegimeMaximum) {
    return (
      <Popover
        id="monthly-savings-ideal-pension-popover"
        trigger={['hover', 'focus']}
        placement="bottom"
        width={362}
        renderTrigger={() => <div>{fieldDisplayer}</div>}
        renderContent={() => (
          <div className="d-flex align-items-start gap-2 p-3">
            <img width={48} src={getIcon('help-light.svg')} alt="" />

            <section className="d-flex flex-column gap-1 text-white">
              <header>
                <h4 className="fs-6 fw-bold mb-0">¡Atención!</h4>
              </header>

              <p className="mb-0">
                El valor de ahorro
                {' '}
                <span className="fw-bold">excede las 50 UF al mes</span>
                {' '}
                para obtener el beneficio tributario del Régimen B
                {' '}
                <span className="fw-bold">(600 UF año)</span>
                .
                {' '}
                Para lograr este nivel de ahorro,
                {' '}
                <span className="fw-bold">debes indicarle al cliente</span>
                {' '}
                que considere invertir la diferencia a través de Depósitos Convenidos con
                {' '}
                <span className="fw-bold">tope de 900 UF al año</span>
                {' '}
                si quisiera maximizar el beneficio tributario.
              </p>
            </section>
          </div>
        )}
      />
    );
  }

  return fieldDisplayer;
}
